import apiConfig from "src/configs/api";
import appConfig from "src/configs/app";
import { noidaServiceRegion, publicCloudUrl } from "src/configs/constants";

export const getRoute = (
  routeName = "",
  id: number | string | string[] = "",
  poolIds: number | string = "",
): string => {
  const routes: { [key: string]: string } = {
    home: "/home",
    loadBalancer: "/load-balancers",
    dashboard: "/dashboard",
    resources: "/dashboard/resources",
    zohoTickets: "/view-tickets",
    zohoDashboard: "/ticket-dashboard",
    volume: "/volume",
    createVolume: "/volume/create-volume",
    s3Policies: "/s3/policies",
    s3Users: "/s3/users",
    s3Bucket: "/s3/buckets",
    s3BucketDetails: `/s3/buckets/${id}`,
    createBucket: "/s3/buckets/create",
    s3BucketSettings: `/s3/buckets/${id}/settings`,
    routers: "/routers",
    routerDetails: `/routers/${id}`,
    interfacesDetails:
      Array.isArray(id) && id.length > 1
        ? `/routers/${id[0]}/interface/${id[1]}`
        : "",
    vpc: "/vpc",
    compute: "/compute",
    autoScaling: "/auto-scaling",
    login:
      appConfig.loginPagePath && appConfig.loginPagePath.length
        ? appConfig.loginPagePath
        : "/login",
    logout: "/logout",
    securityGroup: "/security-group",
    createSecurityGroup: "/security-group/create",
    securityGroupDetails: `/security-group/${id}`,
    securityGroupRuleDetails: `/security-group/${id}`,
    createNewSecurityGroupRule: `/security-group/${id}/create`,
    securityGroups: "/security-groups",
    createSecurityGroups: "/security-groups/create",
    securityGroupsDetails: `/security-groups/${id}`,
    securityGroupRulesDetails: `/security-groups/${id}`,
    createNewSecurityGroupRules: `/security-groups/${id}/create`,
    billingOverview: `/billing/overview`,
    billingSummary: `/billing/summary`,
    createVpc: "/vpc/create-vpc",
    vpcDetails: `/vpc/${id}`,
    subnetDetails:
      Array.isArray(id) && id.length > 1 ? `/vpc/${id[0]}/subnet/${id[1]}` : "",
    portDetails:
      Array.isArray(id) && id.length > 1 ? `/vpc/${id[0]}/port/${id[1]}` : "",
    createSubnet: `/vpc/${id}/subnet/create-subnet`,
    editSubnet:
      Array.isArray(id) && id.length > 1
        ? `/vpc/${id[0]}/subnet/${id[1]}/edit-subnet`
        : "",
    keyPair: "/key-pair",
    keyPairDetails: `/key-pair/${id}`,
    users: "/users",
    userDetails: `/users/${id}`,
    kubernetes: "/kubernetes/overview",
    createCluster: "/kubernetes/overview/create-cluster",
    createNodeGroup: `/kubernetes/overview/${id}/create-node-group`,
    clusterDetails: `/kubernetes/overview/${id}`,
    viewYaml: `/kubernetes/overview/${id}/view-yaml`,
    clusterDetail: `/kubernetes/overview/${id}`,
    k8sLoadBalancer: `/kubernetes/k8s-load-balancer`,
    createLoadBalancer: `/kubernetes/k8s-load-balancer/create`,
    k8sVolumes: "/kubernetes/k8s-volumes/volumes",
    k8sVolumeDetail: `/kubernetes/k8s-volumes/volumes/${id}`,
    k8sSnapshot: "/kubernetes/k8s-volumes/snapshots",
    k8sSnapshotDetail: `/kubernetes/k8s-volumes/snapshots/${id}`,
    k8sSecurityGroups: `/kubernetes/k8s-security-groups`,
    k8sSecurityGroupRules: `/kubernetes/k8s-security-groups/${id}/rules`,
    snapshots: `/snapshots`,
    s3AccessKey: "/s3/accessKey",
    ticketDetails: `/ticket/${id}`,
    createTicket: `/create-ticket`,
    snapshotDetails: `/snapshots/${id}`,
    loadbalancerDetail: `/kubernetes/k8s-load-balancer/${id}`,
    createK8sListener: `/kubernetes/k8s-load-balancer/${id}/create`,
    listenerDetail: Array.isArray(id)
      ? `/kubernetes/k8s-load-balancer/${id[0]}/listeners/${id[1]}`
      : "",
    createK8sPool: Array.isArray(id)
      ? `/kubernetes/k8s-load-balancer/${id[0]}/listeners/${id[1]}/create`
      : "",
    poolDetail: Array.isArray(id)
      ? `/kubernetes/k8s-load-balancer/${id[0]}/listeners/${id[1]}/pools/${id[2]}`
      : "",
    healthDetail: Array.isArray(id)
      ? `/kubernetes/k8s-load-balancer/${id[0]}/listeners/${id[1]}/pools/${id[2]}/healthmonitors/${id[3]}`
      : "",
    memberDetails: Array.isArray(id)
      ? `/kubernetes/k8s-load-balancer/${id[0]}/listeners/${id[1]}/pools/${id[2]}/members/${id[3]}`
      : "",
    l7PolicyDetail: Array.isArray(id)
      ? `/kubernetes/k8s-load-balancer/${id[0]}/listeners/${id[1]}/l7Policies/${id[2]}`
      : "",
    l7RuleDetail: Array.isArray(id)
      ? `/kubernetes/k8s-load-balancer/${id[0]}/listeners/${id[1]}/l7Policies/${id[2]}/l7rules/${id[3]}`
      : "",
    sslCertificate:
      appConfig.oldPortalUrl +
      "/index.php?" +
      "rp=/store/ssl-certifcates-generator",
    publicCloudProjects:
      appConfig.oldPortalUrl + "/index.php?" + "rp=/store/public-cloud",
    myServices: publicCloudUrl + "?action=services",
    addFunds: publicCloudUrl + "?action=addfunds",
    myQuotes: publicCloudUrl + "?action=quotes",
    myInvoices: publicCloudUrl + "?action=invoices",
    creditHistory: publicCloudUrl + "?action=creditmanager",
    paymentMethods:
      appConfig.oldPortalUrl + "/index.php?" + "rp=/account/paymentmethods",
    downloads: appConfig.oldPortalUrl + "/index.php?" + "rp=/download",
    networkStatus: appConfig.oldPortalUrl + "/serverstatus.php",
    announcements: appConfig.oldPortalUrl + "/index.php?" + "rp=/announcements",
    knowledgeBase: appConfig.knowledgeBaseUrl,
    keyPairs: `/key-pairs`,
    keyPairsDetails: `/key-pairs/${id}`,
    instances: "/instances",
    instanceDetails: `/instances/${id}`,
    launchInstance: "/instances/launch-instance",
    spotInstances: "/spot-instances",
    spotInstanceDetails: `/spot-instances/${id}`,
    launchSpotInstance: "/spot-instances/launch-spot-instance",
    interfaceDetails: `/vpc/${id}`,
    selectRegion: "/select-region",
    floatingIP: "/floating-ip",
    volumeDetails: `/volume/${id}`,
    vpn: `/vpn`,
    backup: "/backup",
    backupDetailsPage: `/backup/${id}`,
    IPSecDetails: `/vpn/ipsec-policy/${id}`,
    IPSecCreate: "/vpn/ipsec-policy/create",
    IPSecEdit: `/vpn/ipsec-policy/${id}/edit`,
    serviceNotAvailable: "/service-not-available",
    ikePolicyDetails: `/vpn/ike-policy/${id}`,
    ikePolicyCreate: `/vpn/ike-policy/create`,
    ikePolicyEdit: `/vpn/ike-policy/${id}/edit`,
    VPNServicesDetails: `/vpn/vpn-service/${id}`,
    VPNServicesCreate: "/vpn/vpn-service/create",
    EndpointGroupDetails: `/vpn/endpoint-group/${id}`,
    EndpointGroupCreate: "/vpn/endpoint-group/create",
    IPSecSiteCreate: "/vpn/site-connection/create",
    IPSecSiteDetails: `/vpn/site-connection/${id}`,
    IPSecSiteEdit: `/vpn/site-connection/${id}/edit`,
    getStarted: "/get-started",
    activityLogs: "/activity-logs",
    userManagement: "/iam/users",
    groupManagement: "/iam/groups",
    IAMGroupDetails: `/iam/groups/${id}`,
    IAMUserDetails: `/iam/users/${id}`,
    policyDetails: `/iam/policies/${id}`,
    createIAMPolicy: "/iam/policies/create",
    editIAMPolicy: `/iam/policies/${id}/edit`,
    createIAMGroup: `/iam/groups/create`,
    editIAMGroup: `/iam/groups/${id}/edit`,
    policyManagement: "/iam/policies",
    kycPage: "/customer-details",
    orderProject: "/order-project",
    orderConfirmation: "/order-confirmation",
    backupCenterDashboard: "/backup-center/job-dashboard",
    backupCenterPolicies: "/backup-center/policies",
    backupCenterTasks: "/backup-center/tasks",
    backupCenterNotifications: "/backup-center/notifications",
    createBackupCenterPolicy: "/backup-center/policies/create",
    editBackupCenterPolicy: `/backup-center/policies/${id}/edit`,
    backupCenterPolicyDetails: `/backup-center/policies/${id}`,
    backupCenterConfigureBackup: "/backup-center/configured-backup",
    createBackupCenterConfigure: "/backup-center/configured-backup/create",
    editBackupCenterConfigure: `/backup-center/configured-backup/${id}/edit`,
    backupCenterConfigureDetails: `/backup-center/configured-backup/${id}`,
    register: "/register",
    databases: "/databases",
    monitoring: "/insights/monitoring",
    alerting: "/insights/alerts",
    createAlert: "/insights/alerts/create",
    alertDetails: `/insights/alerts/${id}`,
    alertEdit: `/insights/alerts/${id}/edit`,
    notifications : '/insights/notifications',
    reports : '/insights/reports',
    reportDetails: `/insights/reports/${id}`,
    createReport: "/insights/reports/create",
    editReport: `/insights/reports/${id}/edit`,
    loadBalancerDetails: `/load-balancers/${id}`,
    poolDetails:
      Array.isArray(id) && id.length > 1
        ? `/load-balancers/${id[0]}/pools/${id[1]}`
        : "",
    healthMonitorDetails:
      Array.isArray(id) && id.length > 2
        ? `/load-balancers/${id[0]}/pools/${id[1]}/health-monitors/${id[2]}`
        : "",
    createHealthMonitor:
      Array.isArray(id) && id.length > 1
        ? `/load-balancers/${id[0]}/pools/${id[1]}/health-monitors/create`
        : "",
    editHealthMonitor:
      Array.isArray(id) && id.length > 2
        ? `/load-balancers/${id[0]}/pools/${id[1]}/health-monitors/${id[2]}/edit`
        : "",
    backendServerDetails:
      Array.isArray(id) && id.length > 2
        ? `/load-balancers/${id[0]}/pools/${id[1]}/backend-servers/${id[2]}`
        : "",
    createBackendServer:
      Array.isArray(id) && id.length > 1
        ? `/load-balancers/${id[0]}/pools/${id[1]}/backend-servers/create`
        : "",
    editBackendServer:
      Array.isArray(id) && id.length > 2
        ? `/load-balancers/${id[0]}/pools/${id[1]}/backend-servers/${id[2]}/edit`
        : "",
    listenerDetails:
      Array.isArray(id) && id.length > 1
        ? `/load-balancers/${id[0]}/listeners/${id[1]}`
        : "",
    l7PolicyDetails:
      Array.isArray(id) && id.length > 2
        ? `/load-balancers/${id[0]}/listeners/${id[1]}/l7-policies/${id[2]}`
        : "",
    createL7Rule:
      Array.isArray(id) && id.length > 2
        ? `/load-balancers/${id[0]}/listeners/${id[1]}/l7-policies/${id[2]}/l7-rules/create`
        : "",
    editL7Rule:
      Array.isArray(id) && id.length > 3
        ? `/load-balancers/${id[0]}/listeners/${id[1]}/l7-policies/${id[2]}/l7-rules/${id[3]}/edit`
        : "",
    l7RuleDetails:
      Array.isArray(id) && id.length > 3
        ? `/load-balancers/${id[0]}/listeners/${id[1]}/l7-policies/${id[2]}/l7-rules/${id[3]}`
        : "",
    editPool:
      Array.isArray(id) && id.length > 1
        ? `/load-balancers/${id[0]}/pools/${id[1]}/edit`
        : "",
    createPool: `/load-balancers/${id}/pools/create`,
    editL7Policy:
      Array.isArray(id) && id.length > 2
        ? `/load-balancers/${id[0]}/listeners/${id[1]}/l7-policies/${id[2]}/edit`
        : "",
    createL7Policy:
      Array.isArray(id) && id.length > 1
        ? `/load-balancers/${id[0]}/listeners/${id[1]}/l7-policies/create`
        : "",
    createListener: `/load-balancers/${id}/listeners/create`,
    editListener:
      Array.isArray(id) && id.length > 1
        ? `/load-balancers/${id[0]}/listeners/${id[1]}/edit`
        : "",
    createOSLoadBalancer: "/load-balancers/create",
  };

  return routes[routeName] !== undefined ? routes[routeName] : "";
};

export const getRouteWithParams = (
  page: string,
  id: number | string | string[] = "",
) => {
  // Retrieve values from storage and cookies
  const queryParams = new URLSearchParams(
    window && window.location && window.location.search
      ? window.location.search
      : "",
  );
  const params = {
    [apiConfig.currentRegion]: queryParams.get(apiConfig.currentRegion),
    [apiConfig.timezone]: queryParams.get(apiConfig.timezone),
    [apiConfig.hostingId]: queryParams.get(apiConfig.hostingId),
    [apiConfig.projectId]: queryParams.get(apiConfig.projectId),
  };

  // Filter out undefined, null or empty parameters and create query string
  const queryString = Object.entries(params)
    .filter(
      ([key, value]) => value !== null && value !== undefined && value !== "",
    )
    .map(([key, value]) => `${key}=${encodeURIComponent(value as string)}`) // 'value as string' to assure TypeScript of the type
    .join("&");

  // Construct the full route with query parameters if any
  const route = id !== null && id !== "" ? getRoute(page, id) : getRoute(page);
  return queryString ? `${route}?${queryString}` : route;
};

export const getHorizonPortalURL = (): string => {
  const queryParams = new URLSearchParams(window?.location?.search ?? "");
  const region = queryParams.get(apiConfig.currentRegion);

  return region === noidaServiceRegion
    ? appConfig.noidaHorizonPortalUrl
    : appConfig.horizonPortalUrl;
};
