import { SVGProps } from "react";

const SwitchAccount = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>,
) => {
  return (
    <svg
      width={props.width ? props.width : "19"}
      height={props.height ? props.height : "10"}
      viewBox="0 0 19 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 10C3.61111 10 2.43056 9.51389 1.45833 8.54167C0.486111 7.56944 0 6.38889 0 5C0 3.61111 0.486111 2.43056 1.45833 1.45833C2.43056 0.486111 3.61111 0 5 0H13.3333C14.7222 0 15.9028 0.486111 16.875 1.45833C17.8472 2.43056 18.3333 3.61111 18.3333 5C18.3333 6.38889 17.8472 7.56944 16.875 8.54167C15.9028 9.51389 14.7222 10 13.3333 10H5ZM5 8.75H13.3333C14.375 8.75 15.2604 8.38542 15.9896 7.65625C16.7187 6.92708 17.0833 6.04167 17.0833 5C17.0833 3.95833 16.7187 3.07292 15.9896 2.34375C15.2604 1.61458 14.375 1.25 13.3333 1.25H5C3.95833 1.25 3.07292 1.61458 2.34375 2.34375C1.61458 3.07292 1.25 3.95833 1.25 5C1.25 6.04167 1.61458 6.92708 2.34375 7.65625C3.07292 8.38542 3.95833 8.75 5 8.75ZM13.3529 7.10417C13.9371 7.10417 14.434 6.89972 14.8437 6.49081C15.2535 6.08191 15.4583 5.58538 15.4583 5.00123C15.4583 4.41708 15.2539 3.92014 14.845 3.51042C14.4361 3.10069 13.9395 2.89583 13.3554 2.89583C12.7712 2.89583 12.2743 3.10029 11.8646 3.50919C11.4549 3.91809 11.25 4.41462 11.25 4.99877C11.25 5.58292 11.4544 6.07986 11.8634 6.48958C12.2723 6.89931 12.7688 7.10417 13.3529 7.10417Z"
        fill="#757575"
        className="grey-100-fill"
      ></path>
    </svg>
  );
};

export default SwitchAccount;
