const Dashboard = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        d="M1.69263 16C1.22716 16 0.828688 15.8343 0.497204 15.5028C0.165735 15.1713 0 14.7728 0 14.3074V1.7047C0 1.23592 0.165735 0.834599 0.497204 0.500751C0.828688 0.166917 1.22716 0 1.69263 0H14.2953C14.7641 0 15.1654 0.166917 15.4992 0.500751C15.8331 0.834599 16 1.23592 16 1.7047V14.3074C16 14.7728 15.8331 15.1713 15.4992 15.5028C15.1654 15.8343 14.7641 16 14.2953 16H1.69263ZM6.49829 14.3074V8.64704H1.69263V14.3074H6.49829ZM7.7803 14.3074H14.2953V8.64704H7.7803V14.3074ZM1.69263 7.36503H14.2953V1.7047H1.69263V7.36503Z"
        fill="#4B4B4B"
      />
    </svg>
  );
};

export default Dashboard;
