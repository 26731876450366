// ** React Imports
import { useState } from "react";

// ** MUI Imports
import Fab from "@mui/material/Fab";
import Backdrop from "@mui/material/Backdrop";
import { styled } from "@mui/material/styles";
import Box, { BoxProps } from "@mui/material/Box";

// ** Icons Imports
import ArrowUp from "mdi-material-ui/ArrowUp";

// ** Theme Config Import
import themeConfig from "src/configs/themeConfig";

// ** Type Import
import { LayoutProps } from "src/@core/layouts/types";

// ** Components

import Customizer from "src/@core/components/customizer";

import ScrollToTop from "src/@core/components/scroll-to-top";

// ** Styled Component
import DatePickerWrapper from "src/@core/styles/libs/react-datepicker";

import Footer from "./footer";
import Navigation from "./navigation";
import { Divider } from "@mui/material";
import AppBar from "./appBar";

const VerticalLayoutWrapper = styled("div")({
  flexGrow: 1,
  minWidth: 0,
  display: "flex",
  // minHeight: "100vh",
  flexDirection: "column",
});

const MainContentWrapper = styled("div")<BoxProps>({
  height: "100%",
  display: "flex",
  flexDirection: "column",
});

const ContentWrapper = styled("main")(({ theme }) => ({
  flexGrow: 1,
  width: "100%",
  marginBottom: "60px",
  padding: theme.spacing(6),
  transition: "padding .25s ease-in-out",
  [theme.breakpoints.down("sm")]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  position: "sticky", // Make content wrapper sticky
  top: 0,
}));

const NavigationWrapper = styled("div")<{
  navWidth: number;
  collapsedNavWidth: number;
  navCollapsed: boolean;
  navHover: boolean;
}>((props) => ({
  position: "fixed",
  height: "100%", // Full height
  overflowY: "hidden", // Prevent scrolling within navigation
  zIndex: 5, // Adjust as necessary
  // width: `${props.navCollapsed && !props.navHover ? props.collapsedNavWidth : props.navWidth}px`,
}));

const VerticalLayoutWrapperScrollable = styled("div")<{
  navWidth: number;
  collapsedNavWidth: number;
  navCollapsed: boolean;
  navHover: boolean;
  hidden: boolean;
}>((props) => ({
  flexGrow: 1,
  minWidth: 0,
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  marginLeft: `${props.navCollapsed && !props.navHover ? props.collapsedNavWidth : props.hidden ? 0 : props.navWidth}px`,
}));

const VerticalLayout = (props: LayoutProps) => {
  // ** Props
  const { hidden, settings, children, scrollToTop } = props;

  // ** Vars
  const { skin, contentWidth, navCollapsed, navHidden } = settings;
  const navWidth = themeConfig.navigationSize;
  const navigationBorderWidth = skin === "bordered" ? 1 : 0;
  const collapsedNavWidth = themeConfig.collapsedNavigationSize;

  // ** States
  const [navHover, setNavHover] = useState<boolean>(false);
  const [navVisible, setNavVisible] = useState<boolean>(false);
  const [showBackdrop, setShowBackdrop] = useState<boolean>(false);

  // ** Toggle Functions
  const toggleNavVisibility = () => setNavVisible(!navVisible);

  return (
    <>
      <VerticalLayoutWrapper className="layout-wrapper">
        <AppBar
          setShowBackdrop={setShowBackdrop}
          toggleNavVisibility={toggleNavVisibility}
          {...props}
        />

        <MainContentWrapper className="layout-content-wrapper">
          <NavigationWrapper
            navWidth={navWidth}
            collapsedNavWidth={collapsedNavWidth}
            navCollapsed={navCollapsed}
            navHover={navHover}
          >
            <Navigation
              navWidth={navWidth}
              navHover={navHover}
              navVisible={navVisible}
              setNavHover={setNavHover}
              setNavVisible={setNavVisible}
              collapsedNavWidth={collapsedNavWidth}
              toggleNavVisibility={toggleNavVisibility}
              navigationBorderWidth={navigationBorderWidth}
              {...props}
            />
          </NavigationWrapper>

          <VerticalLayoutWrapperScrollable
            navWidth={navWidth}
            collapsedNavWidth={collapsedNavWidth}
            navCollapsed={navCollapsed}
            navHover={navHover}
            hidden={hidden}
          >
            {/* Content */}
            <ContentWrapper
              className="layout-page-content"
              sx={{
                ...(contentWidth === "boxed" && {
                  mx: "auto",
                  "@media (min-width:1440px)": { maxWidth: 1440 },
                  "@media (min-width:1200px)": { maxWidth: "100%" },
                }),
              }}
            >
              {children}
            </ContentWrapper>
            <Footer showBackdrop={showBackdrop} {...props} />
          </VerticalLayoutWrapperScrollable>
        </MainContentWrapper>

        {/* Backdrop */}
        <Backdrop
          open={showBackdrop}
          onClick={() => setShowBackdrop(false)}
          sx={{ zIndex: 12 }}
        />
      </VerticalLayoutWrapper>

      {/* Scroll to top button */}
      {scrollToTop ? (
        scrollToTop(props)
      ) : (
        <ScrollToTop className="mui-fixed">
          <Fab color="primary" size="small" aria-label="scroll back to top">
            <ArrowUp />
          </Fab>
        </ScrollToTop>
      )}
    </>
  );
};

export default VerticalLayout;
