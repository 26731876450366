import { useRouter } from "next/router";
import React, { createContext, ReactNode, useEffect } from "react";
import apiConfig from "src/configs/api";
import useQueryParams from "src/hooks/useQueryParams";
interface TimezoneContextType {
  timezone: string;
}

interface TimezoneProviderProps {
  children: ReactNode;
}

export const TimezoneContext = createContext<TimezoneContextType | undefined>(
  undefined,
);

export const TimezoneProvider: React.FC<TimezoneProviderProps> = ({
  children,
}) => {
  const queryParams = useQueryParams();
  const router = useRouter();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  useEffect(() => {
    if (!queryParams[apiConfig.timezone]) {
      router.push(
        {
          pathname: router.pathname,
          query: {
            ...router.query,
            [apiConfig.timezone]: timezone,
          },
        },
        undefined,
        { shallow: true },
      );
    }
  }, []);

  return (
    <TimezoneContext.Provider value={{ timezone }}>
      {children}
    </TimezoneContext.Provider>
  );
};
