// ** MUI Import
import React from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import themeConfig from "src/configs/themeConfig";
import Image from "next/image";

const Spinner = ({ height }: { height?: number }) => {
  return (
    <Box
      sx={{
        height: `${height ? height : 100}vh`,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Image
        src={themeConfig.appLogoAlternativePath}
        alt={themeConfig.templateName}
        width="30"
        height="60"
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = themeConfig.appLogoAlternativePath;
        }}
      />
      <CircularProgress disableShrink sx={{ mt: 6 }} />
    </Box>
  );
};

export default Spinner;
