import { SVGProps } from "react";

const Volume = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width ?? "16"}
      height={props.height ?? "16"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        d="M16 3.56939V14.0493C16 14.5895 15.8079 15.0497 15.4237 15.4298C15.0396 15.8099 14.5745 16 14.0285 16H1.95071C1.41046 16 0.950281 15.8099 0.570169 15.4298C0.190056 15.0497 0 14.5895 0 14.0493V1.97147C0 1.4255 0.190056 0.960435 0.570169 0.576269C0.950281 0.19209 1.41046 0 1.95071 0H12.4306L16 3.56939ZM14.0285 4.44099L11.559 1.97147H1.95071V14.0493H14.0285V4.44099ZM7.98475 12.7004C8.5829 12.7004 9.09295 12.491 9.51492 12.0723C9.93688 11.6536 10.1479 11.1452 10.1479 10.547C10.1479 9.94888 9.9385 9.43882 9.51979 9.01686C9.10109 8.5949 8.59266 8.38392 7.9945 8.38392C7.39635 8.38392 6.88629 8.59327 6.46433 9.01198C6.04237 9.43068 5.83139 9.93911 5.83139 10.5373C5.83139 11.1354 6.04074 11.6455 6.45946 12.0674C6.87815 12.4894 7.38658 12.7004 7.98475 12.7004ZM3.05058 6.03891H10.4799V3.07134H3.05058V6.03891ZM1.95071 4.44099V14.0493V1.97147V4.44099Z"
        fill={props.color ?? "#4B4B4B"}
      />
    </svg>
  );
};

export default Volume;
