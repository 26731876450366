// ** MUI Imports
import Box from "@mui/material/Box";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { submitTicketUrl } from "src/configs/constants";
import { Color } from "src/uikit";
import styled from "styled-components";
import { useTheme } from "@mui/material/styles";
import Link from "next/link";
import { getRoute, getRouteWithParams } from "src/routes";
import appConfig from "src/configs/app";

const Separator = styled("span")`
  height: 15px;
  border: 0.5px solid ${Color.footerGrey};
`;

const FooterContent = () => {
  // ** Var
  const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Typography
        sx={{ mr: 2 }}
        textAlign={"center"}
        variant={"body2"}
        fontFamily={"Poppins, sans-serif"}
        fontSize={"12px"}
      >{`Copyright © ${new Date().getFullYear()} Real Time Cloud Services LLC. All Rights Reserved. `}</Typography>
      {hidden ? null : (
        <Box
          sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
          gap={2}
          fontSize={"12px"}
        >
          <Link
            href={String(appConfig.termsAndConditionsUrl)}
            target={"_blank"}
          >
            <Typography
              fontFamily={"Poppins, sans-serif"}
              fontSize={"12px"}
              color={`${theme.palette.primary.main}`}
              variant={"body2"}
            >
              Terms & Conditions
            </Typography>
          </Link>

          <Separator />

          <Link href={getRouteWithParams("createTicket")}>
            <Typography
              fontFamily={"Poppins, sans-serif"}
              fontSize={"12px"}
              variant={"body2"}
            >
              Submit a ticket
            </Typography>
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default FooterContent;
