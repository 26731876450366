import { SVGProps } from "react";

const AccountSecurity = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>,
) => {
  return (
    <svg
      width={props.width ? props.width : "13"}
      height={props.height ? props.height : "16"}
      viewBox="0 0 13 16"
      fill={props.fill ? props.fill : "none"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.52 10.68L10.08 6.12L9.24 5.3L5.58 8.96L3.56 6.94L2.68 7.82L5.52 10.68ZM6.4 16C4.53333 15.5333 3 14.45 1.8 12.75C0.6 11.05 0 9.18667 0 7.16V2.4L6.4 0L12.8 2.4V7.16C12.8 9.18667 12.2 11.05 11 12.75C9.8 14.45 8.26667 15.5333 6.4 16ZM6.4 14.76C7.93333 14.2533 9.18333 13.2967 10.15 11.89C11.1167 10.4833 11.6 8.90667 11.6 7.16V3.24L6.4 1.28L1.2 3.24V7.16C1.2 8.90667 1.68333 10.4833 2.65 11.89C3.61667 13.2967 4.86667 14.2533 6.4 14.76Z"
        fill="#757575"
        className="grey-100-fill"
      ></path>
    </svg>
  );
};

export default AccountSecurity;
