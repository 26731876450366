// ** React Imports
import React, { ReactNode } from "react";

// ** MUI Imports
import { Theme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import VerticalNavItems from "src/layouts/navigation/NavigationList";

// ** Hook Import
import { useSettings } from "src/@core/hooks/useSettings";

import VerticalLayout from "./verticalLayout";
import bottomNavigationList from "src/layouts/navigation/BottomNavigationList";
import { Router } from "mdi-material-ui";
import { useRouter } from "next/router";

interface Props {
  children: ReactNode;
}

const UserLayout = ({ children }: Props) => {
  // ** Hooks
  const { settings, saveSettings } = useSettings();
  

  const router = useRouter()
  const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

  return (
    <VerticalLayout
      hidden={hidden}
      settings={settings}
      saveSettings={saveSettings}
      verticalNavItems={VerticalNavItems()}
      bottomVerticalNavItems={bottomNavigationList()}
    >
      {children}
    </VerticalLayout>
  );
};

export default UserLayout;
