import * as Sentry from '@sentry/nextjs';
import { HTTP_RESPONSE_CODE } from 'src/configs/constants';

export const errorLogInSentry = (errorObj: unknown, payload: unknown, event: string, functionName: string, errorFilePath: string) => {
    let errorMessage: string = "";
    let errorStatusCode: number = HTTP_RESPONSE_CODE.SERVER_ERROR ?? 500;

    if (errorObj && typeof errorObj === "object") {
        if ("message" in errorObj) {
            errorMessage = errorObj.message as string;
        }

        if ("response" in errorObj && errorObj.response && typeof errorObj.response === "object") {
            errorStatusCode = "status" in errorObj.response && errorObj.response.status as number || (HTTP_RESPONSE_CODE.SERVER_ERROR ?? 500);
        }
    }

    try {
        Sentry.withScope((scope) => {
            scope.setExtras({
                message: errorMessage,
                statusCode: errorStatusCode,
                payload: payload ?? {},
                token: localStorage?.getItem("accessToken") || "",
                event: event,
                errorObj: errorObj,
                functionName: functionName,
                errorFilePath: errorFilePath,
            });

            Sentry.captureException(errorObj);
        });
    }
    catch (error: unknown) {
        Sentry.captureException(error);
    }
};