// ** React Imports
import { ElementType, ReactNode } from "react";

// ** Next Imports
import Link from "next/link";
import { useRouter } from "next/router";

// ** MUI Imports
import Chip from "@mui/material/Chip";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import Box, { BoxProps } from "@mui/material/Box";
import ListItemIcon from "@mui/material/ListItemIcon";
import { styled, useTheme } from "@mui/material/styles";
import ListItemButton, {
  ListItemButtonProps,
} from "@mui/material/ListItemButton";

// ** Configs Import
import themeConfig from "src/configs/themeConfig";

// ** Types
import { NavLink, NavGroup } from "src/@core/layouts/types";
import { Settings } from "src/@core/context/settingsContext";

// ** Custom Components Imports

import Translations from "src/figmaLayout/components/Translations";
import CanViewNavLink from "src/figmaLayout/components/acl/CanViewNavLink";

// ** Utils
import { handleURLQueries } from "src/@core/layouts/utils";
import UserIcon from "./UserIcon";
import { Palette } from "mdi-material-ui";
import { Color } from "src/uikit";
import useProjects from "src/hooks/useProjects";
import useKYCStatus from "src/hooks/useKYCStatus";
import { isKycVerified } from "src/configs/helper";
import { routeWithoutKycAndProjects } from "src/configs/constants";
import useModal from "src/hooks/useModal";
import KycDialog from "src/components/dialogs/KYCDialog";
import ProjectDialog from "src/components/dialogs/ProjectDialog";
import FallbackSpinner from "src/@core/components/spinner";
import { getPublicCloudUrl } from "src/utils/publicCloud";

interface Props {
  parent?: boolean;
  item: NavLink;
  navHover?: boolean;
  settings: Settings;
  navVisible?: boolean;
  collapsedNavWidth: number;
  navigationBorderWidth: number;
  toggleNavVisibility: () => void;
  isSubToSub?: NavGroup | undefined;
}

// ** Styled Components
const MenuNavLink = styled(ListItemButton)<
  ListItemButtonProps & {
    component?: ElementType;
    target?: "_blank" | undefined;
  }
>(({ theme }) => ({
  width: "100%",
  color: theme.palette.text.primary,
  transition: "opacity .25s ease-in-out",
  "&.active, &.active:hover": {
    boxShadow: theme.shadows[3],
    backgroundImage: `linear-gradient(98deg, ${theme.palette.customColors.primaryGradient}, ${theme.palette.primary.main} 94%)`,
  },
  "&.active .MuiTypography-root, &.active .MuiSvgIcon-root": {
    color: `${theme.palette.common.white} !important`,
  },
}));

const MenuItemTextMetaWrapper = styled(Box)<BoxProps>({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  transition: "opacity .25s ease-in-out",
  ...(themeConfig.menuTextTruncate && { overflow: "hidden" }),
});

const VerticalNavLink = ({
  item,
  parent,
  navHover,
  settings,
  navVisible,
  isSubToSub,
  collapsedNavWidth,
  toggleNavVisibility,
  navigationBorderWidth,
}: Props) => {
  // ** Hooks
  const theme = useTheme();
  const router = useRouter();
  const { projects } = useProjects();
  const { kycStatus, trialStatus } = useKYCStatus();
  const { handleOpenModal, handleCloseModal, modalActive } = useModal();

  // ** Vars
  const { skin, navCollapsed } = settings;
  const IconTag = item.icon;

  const conditionalBgColor = () => {
    if (skin === "semi-dark" && theme.palette.mode === "light") {
      return {
        color: `rgba(${theme.palette.customColors.dark}, 0.87)`,
        "&:hover": {
          backgroundColor: `rgba(${theme.palette.customColors.dark}, 0.04)`,
        },
      };
    } else if (skin === "semi-dark" && theme.palette.mode === "dark") {
      return {
        color: `rgba(${theme.palette.customColors.light}, 0.87)`,
        "&:hover": {
          backgroundColor: `rgba(${theme.palette.customColors.light}, 0.04)`,
        },
      };
    } else return {};
  };

  const isNavLinkActive = () => {
    if (router.pathname === item.path || handleURLQueries(router, item.path)) {
      return true;
    } else {
      return false;
    }
  };

  const handleClickNavItems = (path: string): boolean => {
    const pathWithoutQuery = path.split("?")[0];
    const kycVerificationStatus =
      kycStatus && trialStatus
        ? isKycVerified(kycStatus, trialStatus.account_type)
        : false;
    if (
      routeWithoutKycAndProjects.includes(pathWithoutQuery) ||
      (kycVerificationStatus && projects)
    ) {
      return true;
    } else if (kycVerificationStatus && !projects) {
      const modulePath = path.split("/")[1];
      if(modulePath === 'iam' || modulePath === 'billing') {
        return true;
      }
      handleOpenModal("projectDialog");
      return false;
    } else {
      handleOpenModal("kycDialog");
      return false;
    }
  };

  return (
    <>
      <CanViewNavLink navLink={item}>
        <ListItem
          sx={{
            px: "0 !important",
            paddingTop: "0px !important",
            paddingBottom: "0px !important",
            "&:hover": {
              backgroundColor: "inherit", // Keeps the background color the same on hover
              // Add any other styles here if you want to override default hover effects
            },
          }}
        >
          <Link passHref href={item.path === undefined ? "/" : `${item.path}`}>
            <MenuNavLink
              component={"a"}
              {...(item.openInNewTab ? { target: "_blank" } : null)}
              onClick={(e) => {
                if (
                  item.path === undefined ||
                  !handleClickNavItems(item.path)
                ) {
                  e.preventDefault();
                  e.stopPropagation();
                }
                if (navVisible) {
                  toggleNavVisibility();
                }
              }}
              sx={{
                ...(parent && item
                  ? { margin: "0px 0px" }
                  : { margin: "4px 0px" }),
                ...(parent && item
                  ? { padding: "6px 0px" }
                  : { padding: "8px 0px" }),
                ...(parent && item
                  ? { borderBottom: "none" }
                  : { borderBottom: `0.5px dashed ${Color.lightGrey2}` }),
                borderRight: isNavLinkActive()
                  ? `2px solid ${theme.palette.primary.main} !important`
                  : "",
                bgcolor: isNavLinkActive() ? `${Color.white4}` : "",

                ...conditionalBgColor(),
                ...(item.disabled
                  ? { pointerEvents: "none" }
                  : { cursor: "pointer" }),
                pl: navCollapsed && !navHover ? 0 : 0,
                width: "176px",
              }}
            >
              {isSubToSub ? null : (
                <ListItemIcon
                  sx={{
                    transition: "margin .25s ease-in-out",
                    ...(navCollapsed && !navHover ? { mr: 0 } : { mr: 2.5 }),
                    ...(parent ? { ml: 3, mr: 3.75 } : {}), // This line should be after (navCollapsed && !navHover) condition for proper styling
                    color: isNavLinkActive()
                      ? `${theme.palette.primary.main}`
                      : "text.primary",
                  }}
                >
                  <UserIcon
                    icon={IconTag}
                    componentType="vertical-menu"
                    iconProps={{
                      sx: {
                        fontSize: "0.875rem",
                        ...(!parent ? { fontSize: "1.5rem" } : {}),
                        ...(parent && item.icon
                          ? { fontSize: "0.875rem" }
                          : {}),
                      },
                    }}
                  />
                </ListItemIcon>
              )}
              <MenuItemTextMetaWrapper
                sx={{
                  ...(isSubToSub ? { marginLeft: 9 } : {}),
                  ...(navCollapsed && !navHover
                    ? { opacity: 0 }
                    : { opacity: 1 }),
                }}
              >
                <Typography
                  {...((themeConfig.menuTextTruncate ||
                    (!themeConfig.menuTextTruncate &&
                      navCollapsed &&
                      !navHover)) && {
                    noWrap: true,
                  })}
                  variant={"body2"}
                  fontSize={"14px !important"}
                  fontFamily={"Poppins, sans-serif"}
                  fontWeight={400}
                  color={
                    isNavLinkActive()
                      ? `${Color.timeBlack}`
                      : `${Color.placeholderBlack}`
                  }
                >
                  <Translations text={item.title} />
                </Typography>

                {item.badgeContent ? (
                  <Chip
                    label={item.badgeContent}
                    color={item.badgeColor || "primary"}
                    sx={{
                      height: 20,
                      fontWeight: 500,
                      marginLeft: 1.25,
                      "& .MuiChip-label": {
                        px: 1.5,
                        textTransform: "capitalize",
                      },
                    }}
                  />
                ) : null}
              </MenuItemTextMetaWrapper>
            </MenuNavLink>
          </Link>
          {/* <Divider/> */}
        </ListItem>
      </CanViewNavLink>
      {modalActive.modalName === "kycDialog" && modalActive.isModalOpen && (
        <KycDialog
          open={
            modalActive.isModalOpen && modalActive.modalName === "kycDialog"
          }
          onCloseModal={handleCloseModal}
        />
      )}
      {modalActive.modalName === "projectDialog" && modalActive.isModalOpen && (
        <ProjectDialog
          open={
            modalActive.isModalOpen && modalActive.modalName === "projectDialog"
          }
          onCloseModal={handleCloseModal}
        />
      )}
    </>
  );
};

export default VerticalNavLink;
