export const PERMISSION_DENIED_RESPONSE = "Permission Denied";

export const UNAUTHORIZED_REQUEST_RESPONSE = "Unauthorized Request";

export const INVALID_REQUEST = "Invalid Request";

export const EMPTY_DATA = "Data not found";

export const UNPROCESSABLE_ENTITY = "unprocessable_entity";

export const PERMISSION_DENIED = "permission_denied";

export const SUCCESS = "success";

export const INVALID_CREDENTIALS = "invalid_credentials";

export const SERVER_ERROR = "server_error";

export const SERVICE_UNAVAILABLE = "service_unavailable";

export const INVALID_LOGIN_ID = "Invalid Login ID provided";

export const INVALID_CREDENTIALS_RESPONSE = "Invalid credentials";

export const REGION_REQUIRED_RESPONSE = "Region is required";

export const INVALID_REGION_RESPONSE = "Invalid region";

export const BUCKET_NAME_REQUIRED_RESPONSE = "Bucket name is required";

export const SERVER_ERROR_MESSAGE =
  "Unable to process this request. Please try again later.";

export const TIMEOUT_ERROR_MESSAGE =
  "Unable to process this request. Please try again later.";

export const CONTACT_SUPPORT_MESSAGE =
  "Oops! Something went wrong. Please contact the support team for further assistance.";

export const METHOD_NOT_ALLOWED = "Method not allowed";

export const toastMessage: { [key: string]: { [key: string]: string } } = {
  create: {
    scheduleInProgress: "Scheduling creation of",
    schedule: "Scheduled creation of",
    success: "Successfully created",
    error: "Unable to create",
    error2: "Kindly retry.",
  },
  retrieve: {
    schedule: "Scheduled fetching",
    success: "Successfully fetched",
    error: "Unable to fetch",
    error2: "Kindly retry.",
  },
  update: {
    schedule: "Updating",
    success: "Successfully updated",
    error: "Unable to update",
    error2: "Kindly retry.",
  },
  delete: {
    schedule: "Scheduled deleting",
    success: "Successfully deleted",
    successMultiple: "Successfully deleted the selected",
    error: "Unable to delete",
    error2: "Kindly retry.",
    error3: "Unable to delete all the selected",
  },
  backendError: { errorMessage: "Error occurred while processing the request" },
  frontendError: {
    errorMessage: "Unable to process the request. Please try again later",
    projectNotFound:
      "Project doesn't exist in this region. Please try changing the region.",
    serviceNotAvailable:
      "Service not available in this region. Try changing the region.",
    genericErrorMessage: "Oops! Something went wrong",
    projectNotFoundErrorMessage: "Project not found",
    dashboardRedirectionMessage: "Redirecting to dashboard page",
    regionNotFoundErrorMessage: "Region not found",
    sessionTimeoutErrorMessage: "Oops! Something went wrong",
    projectNotActivated: `This region is not available yet. To enable, kindly click the 'Get Started' button.`,
    defaultProjectIdErrorMessage:
      "Project not found. Redirecting to default project",
    projectFetchingErrorMessage:
      "Unable to fetch projects. Kindly try again after sometime",
    kycErrorMessage:
      "Unable to fetch KYC details. Kindly try again after sometime",
  },
};

export const S3Messages = {
  objectLockingNotFoundMessage:
    "Object locking configuration not found for this bucket.",
};

export const GENERIC_ERROR_MESSAGE = "Oops! Something went wrong";
export const ProjectNotFound = "project not found or access denied";
export const PROJECT_UNAVAILABLE = "Project is not available in this region";
export const descriptionErrorMessage =
  "Allowed characters are ASCII letters [a-z, A-Z], digits [0-9], whitespace and special characters including dot, comma, underscore and hyphen";
export const nameErrorMessage =
  "Allowed characters are ASCII letters [a-z, A-Z], digits [0-9] and special characters including [_,-]";

export const publiclyExposedWarning =
  "Rules with destination of 0.0.0.0/0 or ::/0 allow your instances to send traffic to any IPv4 or IPv6 address. We recommend setting security group rules to be more restrictive and to only allow traffic to specific known IP addresses.";

export const inviteNewUserDescription = "Inviting a new user allows you to invite a new user to your account. If the invitee already has an existing user account, they will be able to access your account using their existing login credentials. If the user does not yet have a user account, they will be able to create one."

export const invitationSent = "Invitation sent"

export const bucketMessages =  {
  bucketNotExists: "This bucket name is not available",
  bucketValidationError : "Error validating bucket name"
}

export const databaseAccessRulesWarning = "Your databases can be accessed by any IP address, by default. So, it's highly recommended that you restrict access to your databases by whitelisting only those access points or IP addresses, that you trust!"

export const searchPlaceholderTable = "Search by keyword"; 

export const minAndMaxErrorMessages: { min: string; max: string } = {
  min: "Value should be greater than or equal to",
  max: "Value should be less than or equal to",
};