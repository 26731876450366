import { SVGProps } from "react";

const Contacts = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width ? props.width : "14"}
      height={props.height ? props.height : "14"}
      viewBox="0 0 14 14"
      fill={props.fill ? props.fill : "none"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.16667 12.1139C1.94444 11.388 2.82525 10.8014 3.80909 10.3542C4.79294 9.90695 5.8559 9.68333 6.99798 9.68333C8.14007 9.68333 9.2037 9.90695 10.1889 10.3542C11.1741 10.8014 12.0556 11.388 12.8333 12.1139V1.16667H1.16667V12.1139ZM7.03889 8.14722C7.79074 8.14722 8.42593 7.88796 8.94444 7.36944C9.46296 6.85093 9.72222 6.21574 9.72222 5.46389C9.72222 4.71204 9.46296 4.07685 8.94444 3.55833C8.42593 3.03981 7.79074 2.78056 7.03889 2.78056C6.28704 2.78056 5.65185 3.03981 5.13333 3.55833C4.61481 4.07685 4.35556 4.71204 4.35556 5.46389C4.35556 6.21574 4.61481 6.85093 5.13333 7.36944C5.65185 7.88796 6.28704 8.14722 7.03889 8.14722ZM1.16667 14C0.855556 14 0.583333 13.8833 0.35 13.65C0.116667 13.4167 0 13.1444 0 12.8333V1.16667C0 0.855556 0.116667 0.583333 0.35 0.35C0.583333 0.116667 0.855556 0 1.16667 0H12.8333C13.1444 0 13.4167 0.116667 13.65 0.35C13.8833 0.583333 14 0.855556 14 1.16667V12.8333C14 13.1444 13.8833 13.4167 13.65 13.65C13.4167 13.8833 13.1444 14 12.8333 14H1.16667ZM2.23611 12.8333H11.7639V12.6583C11.0639 12.062 10.312 11.6116 9.50833 11.3069C8.70463 11.0023 7.86852 10.85 7 10.85C6.13148 10.85 5.29537 11.0023 4.49167 11.3069C3.68796 11.6116 2.93611 12.062 2.23611 12.6583V12.8333ZM7.03889 6.98056C6.61759 6.98056 6.25949 6.8331 5.96458 6.53819C5.66968 6.24329 5.52222 5.88519 5.52222 5.46389C5.52222 5.04259 5.66968 4.68449 5.96458 4.38958C6.25949 4.09468 6.61759 3.94722 7.03889 3.94722C7.46019 3.94722 7.81829 4.09468 8.11319 4.38958C8.4081 4.68449 8.55556 5.04259 8.55556 5.46389C8.55556 5.88519 8.4081 6.24329 8.11319 6.53819C7.81829 6.8331 7.46019 6.98056 7.03889 6.98056Z"
        fill="#757575"
        className="grey-100-fill"
      ></path>
    </svg>
  );
};

export default Contacts;
