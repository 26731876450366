import { SVGProps } from "react";

const Logs = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      version="1.1"
      id="logs"
      width={props.width || "16"}
      height={props.height || "16"}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 452.986 452.986"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <g>
            <path
              fill="#010002;"
              d="M403.87,0H49.095C22.11,0,0,22.11,0,49.117v354.796c0,26.963,22.11,49.074,49.095,49.074H403.87
				c27.007,0,49.117-22.11,49.117-49.074V49.117C452.986,22.11,430.876,0,403.87,0z M415.82,410.859H37.188V101.836H415.82V410.859z
				"
            />
            <circle fill="#010002;" cx="124.765" cy="169.029" r="36.843" />
            <rect
              x="192.282"
              y="153.692"
              fill="#010002;"
              width="161.716"
              height="30.695"
            />
            <circle fill="#010002;" cx="124.765" cy="259.065" r="36.843" />
            <rect
              x="192.282"
              y="243.728"
              fill="#010002;"
              width="161.716"
              height="30.674"
            />
            <circle fill="#010002;" cx="124.765" cy="349.123" r="36.843" />
            <rect
              x="192.282"
              y="333.765"
              fill="#010002;"
              width="161.716"
              height="30.674"
            />
          </g>
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </g>
    </svg>
  );
};

export default Logs;
