import { SVGProps } from "react";

const EmailHistory = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>,
) => {
  return (
    <svg
      width={props.width ? props.width : "14"}
      height={props.height ? props.height : "14"}
      viewBox="0 0 14 14"
      fill={props.fill ? props.fill : "none"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7 0L13.4925 3.885C13.6908 3.99 13.825 4.13292 13.895 4.31375C13.965 4.49458 14 4.6725 14 4.8475V12.95C14 13.23 13.895 13.475 13.685 13.685C13.475 13.895 13.23 14 12.95 14H1.05C0.77 14 0.525 13.895 0.315 13.685C0.105 13.475 0 13.23 0 12.95V4.8475C0 4.6725 0.0379167 4.49458 0.11375 4.31375C0.189583 4.13292 0.320833 3.99 0.5075 3.885L7 0ZM7 8.155L12.88 4.7075L7 1.1725L1.12 4.7075L7 8.155ZM7 9.3275L1.05 5.8275V12.95H12.95V5.8275L7 9.3275ZM7 12.95H12.95H1.05H7Z"
        fill="#757575"
        className="grey-100-fill"
      ></path>
    </svg>
  );
};

export default EmailHistory;
