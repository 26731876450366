import { AxiosResponse } from "axios";

import axios from "src/services/axios";
import apiConfig from "src/configs/api";
import { errorLogInSentry } from "src/store/sentry";
import { Country, KYCRequestBody } from "src/types/kyc";

export const sendAadhaarOTP = async (aadhaarNumber: string) => {
  const aadhaarOTPResponse = await axios.post(apiConfig.aadharOTPRequest, {
    value: aadhaarNumber,
    type: "sendAadharOtp",
  });
  return aadhaarOTPResponse?.data ? aadhaarOTPResponse.data : {};
};

export const verifyAadhaarOTP = async (aadhaarNumber: string, otp: string) => {
  const aadhaarOTPVerificationResponse = await axios.post(
    apiConfig.verifyaadharOTP,
    {
      value: aadhaarNumber,
      type: "verifyAadharOtp",
      otp,
    },
  );
  return aadhaarOTPVerificationResponse?.data
    ? aadhaarOTPVerificationResponse.data
    : {};
};

export const updateKYCInfoWithoutFile = async (requestBody: Partial<KYCRequestBody>) => {
  try {
    const updateKYCInfoResponse = await axios.post(
      apiConfig.kycUpdateInfo,
      requestBody,
    );
    return updateKYCInfoResponse?.data ? updateKYCInfoResponse.data : {};
  } catch (error: unknown) {
    throw error;
  }
}

export const updateKYCInfo = async (
  requestBody: Partial<KYCRequestBody>,
  file: File | null,
) => {
  const formData = new FormData();

  if (file) {
    // Create a fields object for all keys except 'file'
    const fields: Record<string, any> = {};

    for (const key in requestBody) {
      if (
        requestBody[key as keyof KYCRequestBody] !== undefined &&
        key !== "file"
      ) {
        let value = requestBody[key as keyof KYCRequestBody];

        if (key === "terms_services" || key === "privacy_policy") {
          value = Boolean(value);
        }
        fields[key] = value;
      }
    }

    // Append the fields object as a JSON string with Content-Type: application/json
    formData.append("fields", JSON.stringify(fields));

    // Append the file separately with Content-Type: application/octet-stream

    formData.append("file", file, file.name);
  }

  try {
    const updateKYCInfoResponse = await axios.post(
      `${apiConfig.kycUpdateInfo}`,
      file ? formData : requestBody,
      {
        headers: {
          "Content-Type": file ? "multipart/form-data" : "application/json",
        },
      },
    );

    return updateKYCInfoResponse?.data ? updateKYCInfoResponse.data : {};
  } catch (error: unknown) {
    throw error;
  }
};

export const fetchCountries = async (): Promise<{ data: Country[] }> => {
  try {
    const response: AxiosResponse<{ data: Country[] }> = await axios.get(
      apiConfig.fetchCountries,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchUserKYCStatus = async (): Promise<
  | {
    [key: string]: {
      [key: string]: string | boolean | number;
    }
  }
  | undefined
> => {
  try {
    const response = await axios.get(apiConfig.kycStatus);
    return response?.data ?? {};
  } catch (error) {
    errorLogInSentry(
      error,
      {},
      "GET",
      "fetchUserKYCStatus",
      "src/store/kyc/index.ts",
    );
  }
};

export const sendEmailOTP = async (email: string): Promise<{ email_exists: boolean }> => {
  try {
    const response = await axios.post(apiConfig.sendEmailOTP, {
      value: email,
      type: "sendEmailOtp",
    });
    return response?.data?.data ? response.data.data : {};
  } catch (error: unknown) {
    throw error;
  }
};

export const verifyEmailOTP = async (email: string, otp: string): Promise<{ email_verified: boolean }> => {
  try {
    const response = await axios.post(apiConfig.verifyEmailOTP, {
      value: email,
      type: "verifyEmailOtp",
      otp
    });
    return response?.data?.data ? response.data.data : {};
  } catch (error: unknown) {
    throw error;
  }
};