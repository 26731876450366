import { SVGProps } from "react";

const Compute = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width ?? "16"}
      height={props.height ?? "16"}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        d="M1.71558 0.296876C1.24049 0.296876 0.835786 0.464031 0.501472 0.798347C0.167157 1.13265 0 1.53735 0 2.01245L0 12.7393H1.71558L1.71558 2.01245L10.1129 2.01245V0.296876L1.71558 0.296876ZM4.49661 3.09597C4.02649 3.09597 3.62603 3.26136 3.29526 3.59213C2.96448 3.92291 2.7991 4.32336 2.7991 4.79349L2.7991 14.5813C2.7991 15.0564 2.96448 15.4611 3.29526 15.7954C3.62603 16.1297 4.02649 16.2969 4.49661 16.2969H14.6456C15.1207 16.2969 15.5254 16.1297 15.8597 15.7954C16.194 15.4611 16.3612 15.0564 16.3612 14.5813V4.79349C16.3612 4.32336 16.194 3.92291 15.8597 3.59213C15.5254 3.26136 15.1207 3.09597 14.6456 3.09597L4.49661 3.09597ZM4.49661 4.79349L14.6456 4.79349L14.6456 14.5813L4.49661 14.5813L4.49661 4.79349Z"
        fill={props.color ?? "#4B4B4B"}
      />
    </svg>
  );
};

export default Compute;
