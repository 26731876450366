// ** React Import
import { ReactNode } from "react";

// ** Next Import
import Link from "next/link";

// ** MUI Imports
import IconButton from "@mui/material/IconButton";
import Box, { BoxProps } from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import Typography, { TypographyProps } from "@mui/material/Typography";

// ** Icons
import Close from "mdi-material-ui/Close";

// ** Type Import
import { Settings } from "src/@core/context/settingsContext";
import { getPublicCloudUrl } from "src/utils/publicCloud";

interface Props {
  hidden: boolean;
  navHover: boolean;
  settings: Settings;
  collapsedNavWidth: number;
  menuLockedIcon?: ReactNode;
  menuUnlockedIcon?: ReactNode;
  navigationBorderWidth: number;
  toggleNavVisibility: () => void;
  saveSettings: (values: Settings) => void;
  verticalNavMenuBranding?: (props?: any) => ReactNode;
  title: string;
}

// ** Styled Components
const MenuHeaderWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingRight: "8px",
  transition: "padding .5s ease-in-out",
  marginTop: "26px",
  marginBottom: "8px",
  // minHeight: theme.mixins.toolbar.minHeight
}));

const HeaderTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: "600 !important",
  lineHeight: "normal",
  fontSize: "18px !important",
  letterSpacing: "0.09px",
  color: theme.palette.text.primary,
  transition: "opacity .5s ease-in-out, margin .5s ease-in-out",
  width: "100%",
  textWrap: "nowrap",
  fontFamily: "Inter, sans-serif",
}));

const StyledLink = styled("a")({
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
});

const VerticalNavHeader = (props: Props) => {
  // ** Props
  const {
    hidden,
    navHover,
    settings,
    saveSettings,
    collapsedNavWidth,
    toggleNavVisibility,
    navigationBorderWidth,
    menuLockedIcon: userMenuLockedIcon,
    menuUnlockedIcon: userMenuUnlockedIcon,
    verticalNavMenuBranding: userVerticalNavMenuBranding,
    title,
  } = props;

  // ** Hooks
  const theme = useTheme();

  // ** Vars
  const { navCollapsed } = settings;

  const menuHeaderPaddingLeft = () => {
    if (navCollapsed && !navHover) {
      if (userVerticalNavMenuBranding) {
        return 0;
      } else {
        return (collapsedNavWidth - navigationBorderWidth - 30) / 8;
      }
    } else {
      return 6;
    }
  };

  return (
    <MenuHeaderWrapper className="nav-header">
      <Link href={getPublicCloudUrl()} passHref>
        <StyledLink>
          <HeaderTitle>
            {navCollapsed && !navHover ? title[0] : title}
          </HeaderTitle>
        </StyledLink>
      </Link>
      {hidden ? (
        <IconButton
          disableRipple
          disableFocusRipple
          onClick={toggleNavVisibility}
          sx={{ padding: 0, backgroundColor: "transparent !important" }}
        >
          <Close fontSize="small" />
        </IconButton>
      ) : null}
    </MenuHeaderWrapper>
  );
};

export default VerticalNavHeader;
