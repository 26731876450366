import Router from "next/router";
import apiConfig from "src/configs/api";
import { getLocalStoreItem } from "src/configs/helper";
import { getRoute } from "src/routes";
import { useSelector } from "react-redux";
import { publicCloudUrl } from "src/configs/constants";

export const removeEmptyQuery = (obj: {
  [key: string]: string | undefined | null;
}) => {
  const newObj: { [key: string]: string } = {};
  Object.keys(obj).forEach((key: string) => {
    if (obj[key] !== undefined && obj[key] !== null) {
      newObj[key] = obj[key] as string;
    }
  });

  return newObj;
};

export interface DateTimeFormatOptions {
  year?: "numeric" | "2-digit";
  month?: "numeric" | "2-digit" | "long" | "short" | "narrow";
  day?: "numeric" | "2-digit";
  hour?: "numeric" | "2-digit";
  minute?: "numeric" | "2-digit";
  second?: "numeric" | "2-digit";
  weekday?: "long" | "short" | "narrow";
  timeZoneName?: "long" | "short";
  hour12?: boolean;
}

export function formatDateTime(dateTimeString: string): string {
  const date = new Date(dateTimeString);

  const options: DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  return date.toLocaleString("en-US", options);
}

export const calculateTimeDifference = (createdTime: string) => {
  const currentTime = new Date();
  const ticketCreatedTime = new Date(createdTime);

  const differenceInSeconds = Math.floor(
    (currentTime.getTime() - ticketCreatedTime.getTime()) / 1000,
  );

  const secondsInMinute = 60;
  const secondsInHour = 3600;
  const secondsInDay = 86400;
  const secondsInMonth = 2592000;

  const minutes = Math.floor(differenceInSeconds / secondsInMinute);
  const hours = Math.floor(differenceInSeconds / secondsInHour);
  const days = Math.floor(differenceInSeconds / secondsInDay);
  const months = Math.floor(differenceInSeconds / secondsInMonth);

  if (differenceInSeconds < secondsInMinute) {
    return "Few seconds ago";
  } else if (differenceInSeconds < secondsInHour) {
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else if (differenceInSeconds < secondsInDay) {
    return `${hours} h ago`;
  } else if (differenceInSeconds < secondsInMonth) {
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else {
    return `${months} month${months > 1 ? "s" : ""} ago`;
  }
};

export const calculateTimeRange = (hours: number) => {
  const currentDate = new Date();
  const targetDate = new Date(currentDate.getTime() - hours * 60 * 60 * 1000);

  const localTargetDate = new Date(
    targetDate.getTime() - targetDate.getTimezoneOffset() * 60 * 1000,
  );

  return localTargetDate.toISOString();
};

export const computeToAndFromTime = (range: string) => {
  let fromTime, toTime;
  switch (range) {
    case "last24hours":
      toTime = calculateTimeRange(0);
      fromTime = calculateTimeRange(1 * 24);
      break;
    case "last7days":
      toTime = calculateTimeRange(0);
      fromTime = calculateTimeRange(7 * 24);
      break;
    case "last14days":
      toTime = calculateTimeRange(0);
      fromTime = calculateTimeRange(14 * 24);
      break;
    case "last30days":
      toTime = calculateTimeRange(0);
      fromTime = calculateTimeRange(30 * 24);
      break;
    default:
      break;
  }

  return { fromTime, toTime };
};

export const formatSize = (bytes: string): string => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const fileSize = parseInt(bytes, 10);

  if (fileSize === 0) return "0 Byte";

  const i = Math.floor(Math.log(fileSize) / Math.log(1024));
  const sizeFormatted = (fileSize / Math.pow(1024, i)).toFixed(2);

  return `${parseFloat(sizeFormatted)} ${sizes[i]}`;
};

export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(";").shift();
};

export const ageHelper = (creationTime: string) => {
  const creationDate: Date = new Date(creationTime);
  const creationDateMs: number = creationDate.getTime();
  const currentDate: number = Date.now();
  const ageInMilliseconds: number = currentDate - creationDateMs;
  const millisecondsPerSecond: number = 1000;
  const millisecondsPerMinute: number = millisecondsPerSecond * 60;
  const millisecondsPerHour: number = millisecondsPerMinute * 60;
  const millisecondsPerDay: number = millisecondsPerHour * 24;

  const days: number = Math.floor(ageInMilliseconds / millisecondsPerDay);
  const remainingMilliseconds: number = ageInMilliseconds % millisecondsPerDay;
  const hours: number = remainingMilliseconds / millisecondsPerHour;
  const remainingMinutes: number = Math.floor(
    (remainingMilliseconds % millisecondsPerHour) / millisecondsPerMinute,
  );
  const remainingSeconds: number = Math.floor(
    (remainingMilliseconds % millisecondsPerMinute) / millisecondsPerSecond,
  );

  let ageString: string = "";
  if (days > 0) {
    ageString = days + " day" + (days > 1 ? "s" : "");
  } else if (hours >= 1) {
    ageString = hours.toFixed(1) + " hours";
  } else if (remainingMinutes > 0) {
    ageString =
      remainingMinutes + " minute" + (remainingMinutes > 1 ? "s" : "");
  } else {
    ageString =
      remainingSeconds + " second" + (remainingSeconds > 1 ? "s" : "");
  }

  const time: string = creationDate.toLocaleString("en-us", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZoneName: "short",
  });

  return ageString;
};

const returnWorkloadPodsState = (
  message: string,
  succeeded: number | undefined,
) => {
  const regex = /success:\s*(\d+),\s*active:\s*(\d+),\s*failed:\s*(\d+)/;
  const match = message.match(regex);
  if (match) {
    const [, success, active, failed] = match.map(Number);
    return { success, active, failed };
  } else {
    return { success: succeeded, active: 0, failed: 0 };
  }
};
export const fetchToken = async (): Promise<string | null> => {
  try {
    const token = await localStorage.getItem(apiConfig.storageTokenKeyName);

    if (token && typeof token === "string" && token.trim() !== "") {
      return token;
    } else {
      throw new Error("Invalid token");
    }
  } catch (e) {
    // Silent error, no need to show to the user
    Router.push(getRoute("logout"));
    throw new Error("Unauthorized");
  }
};

export const throttle = <F extends (...args: Parameters<F>) => void>(
  func: F,
  limit: number,
) => {
  let inThrottle: boolean;
  return (...args: Parameters<F>) => {
    if (!inThrottle) {
      func(...args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  };
};

export const truncateText = (text: string | undefined, maxLength: number) => {
  if (!text) return "";
  return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
};

export const getTodayInDateTimeLocalFormat = () => {
  const now = new Date();
  const year = now.getFullYear().toString().padStart(4, "0");
  const month = (now.getMonth() + 1).toString().padStart(2, "0");
  const day = now.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}T00:00`;
};

export function convertISOFormatToString(inputDate: string) {
  const inputDateObject = new Date(decodeURIComponent(inputDate));
  const year = inputDateObject.getFullYear();
  const month = (inputDateObject.getMonth() + 1).toString().padStart(2, "0");
  const day = inputDateObject.getDate().toString().padStart(2, "0");
  const hours = inputDateObject.getHours().toString().padStart(2, "0");
  const minutes = inputDateObject.getMinutes().toString().padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}`;
}

export const getCurrentDateTime = (includeTime: boolean) => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");

  if (includeTime) {
    const hours = String(today.getHours()).padStart(2, "0");
    const minutes = String(today.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  } else {
    const defaultTime = "00:00";
    return `${year}-${month}-${day}T${defaultTime}`;
  }
};

export const redirectToHomePage = () => {
  window.location.href = publicCloudUrl;
};
