import {
  useState,
  useEffect,
  useCallback,
  createContext,
  FC,
  ReactNode,
  useMemo,
} from "react";

import { useAuth } from "src/hooks/useAuth";
import { fetchUserKYCStatus } from "src/store/kyc";
import { CustomerAccountType, UserKYCStatusType } from "src/context/types";
import showToast from "src/utils/notifications";
import { isEmptyObject } from "src/configs/helper";
import { toastMessage } from "src/language/en/message";

interface KYCContextType {
  kycStatus: UserKYCStatusType | null;
  userDetails: { email: string; aadharNumber: string };
  isKYCFetching: boolean;
  trialStatus: CustomerAccountType | null;
  setRefreshKYCStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultValues = {
  kycStatus: null,
  userDetails: { email: "", aadharNumber: "" },
  isKYCFetching: false,
  trialStatus: null,
  setRefreshKYCStatus: () => {},
};

export const KYCContext = createContext<KYCContextType>(defaultValues);

const KYCProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { user } = useAuth();
  const [userKYCStatus, setUserKYCStatus] = useState<UserKYCStatusType | null>(
    null,
  );
  const [userTrialStatus, setUserTrialStatus] =
    useState<CustomerAccountType | null>(null);
  const [userDetails, setUserDetails] = useState<{
    email: string;
    aadharNumber: string;
  }>({
    email: "",
    aadharNumber: "",
  });
  const [isKYCLoading, setIsKYCLoading] = useState<boolean>(true);
  const [refreshKYCStatus, setRefreshKYCStatus] = useState<boolean>(true);

  const getUserKYCDetails = useCallback(async () => {
    if (!user) {
      return;
    }
    setIsKYCLoading(true);
    try {
      const kycStatusResponse = await fetchUserKYCStatus();
      let userKYCDetails = null;
      let userTrialDetails = null;
      let clientDetails = { email: "", aadharNumber: "" };
      if (kycStatusResponse?.user && !isEmptyObject(kycStatusResponse.user)) {
        userKYCDetails = {
          user_type: kycStatusResponse.user.user_type as string,
          kycDone: kycStatusResponse.user.kycDone as boolean,
          isPhoneVerified: kycStatusResponse.user.isPhoneVerified as boolean,
          isEmailVerified: kycStatusResponse.user.isEmailVerified as boolean,
          isAadharVerified: kycStatusResponse.user.isAadharVerified as boolean,
          isGSTVerified: kycStatusResponse.user.isGSTVerified as boolean,
          isIndia: kycStatusResponse.user.isIndia as boolean,
          gst_status: kycStatusResponse.user.gst_status as number,
          sumSubVerificationStatus: kycStatusResponse.user
            .sumSubVerificationStatus as boolean | null,
        };
      }
      if (
        kycStatusResponse?.customerAccountType &&
        !isEmptyObject(kycStatusResponse.customerAccountType)
      ) {
        userTrialDetails = {
          id: kycStatusResponse.customerAccountType.id as number,
          client_id: kycStatusResponse.customerAccountType.client_id as number,
          account_type: kycStatusResponse.customerAccountType
            .account_type as string,
          account_status: kycStatusResponse.customerAccountType
            .account_status as string,
          created_at: kycStatusResponse.customerAccountType
            .created_at as string,
          updated_at: kycStatusResponse.customerAccountType
            .updated_at as string,
        };
      }
      if (
        kycStatusResponse?.clientDetails &&
        !isEmptyObject(kycStatusResponse.clientDetails)
      ) {
        clientDetails.email =
          (kycStatusResponse.clientDetails.email as string) ?? "";
        const userAadhar = kycStatusResponse?.user?.aadhar_number;
        const maskedAadhar =
          userAadhar && typeof userAadhar === "string" && userAadhar.length > 0
            ? `XXXXXXXX${userAadhar.slice(userAadhar.length - 4, userAadhar.length)}`
            : "";
        clientDetails.aadharNumber = maskedAadhar;
      }
      setUserKYCStatus(userKYCDetails);
      setUserTrialStatus(userTrialDetails);
      setUserDetails(clientDetails);
    } catch (error) {
      showToast("error", toastMessage.frontendError.kycErrorMessage);
    } finally {
      setIsKYCLoading(false);
    }
  }, [user]);

  useEffect(() => {
    if (user && refreshKYCStatus) {
      getUserKYCDetails();
      setRefreshKYCStatus(false);
    }
  }, [user, getUserKYCDetails, refreshKYCStatus]);

  const contextValue = useMemo(
    () => ({
      kycStatus: userKYCStatus,
      userDetails: userDetails,
      isKYCFetching: isKYCLoading,
      setRefreshKYCStatus: setRefreshKYCStatus,
      trialStatus: userTrialStatus,
    }),
    [
      userKYCStatus,
      isKYCLoading,
      setRefreshKYCStatus,
      userTrialStatus,
      userDetails,
    ],
  );

  return (
    <KYCContext.Provider value={contextValue}>{children}</KYCContext.Provider>
  );
};

export default KYCProvider;
