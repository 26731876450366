const Zoho = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        d="M7.98225 9.79507C7.48934 9.79507 7.06649 9.621 6.71371 9.27286C6.36092 8.92474 6.18452 8.50623 6.18452 8.01735C6.18452 7.52848 6.35886 7.10765 6.70754 6.75486C7.05621 6.40208 7.47536 6.22568 7.96498 6.22568C8.46033 6.22568 8.88578 6.39721 9.24133 6.74025C9.59689 7.08332 9.77467 7.50528 9.77467 8.00614C9.77467 8.50702 9.59916 8.93039 9.24814 9.27626C8.89714 9.62214 8.47517 9.79507 7.98225 9.79507ZM7.96503 16C5.76028 16 3.87687 15.2223 2.31481 13.6669C0.752713 12.1115 -0.018773 10.2191 0.000346789 7.98962H1.93031C1.94625 9.66779 2.53845 11.0973 3.70691 12.2781C4.87539 13.4589 6.29616 14.0493 7.96922 14.0493C9.66182 14.0493 11.0914 13.4637 12.2581 12.2925C13.4248 11.1214 14.0081 9.69518 14.0081 8.01388C14.0081 6.33259 13.4248 4.90519 12.2581 3.73169C11.0914 2.55821 9.66182 1.97147 7.96922 1.97147C7.14527 1.97147 6.35822 2.13748 5.60807 2.46952C4.85791 2.80156 4.19231 3.26502 3.61124 3.85992H5.33368V5.37484H0.58141V0.664073H2.07557V2.63554C2.85033 1.80545 3.74613 1.15867 4.76299 0.695201C5.77985 0.231734 6.84566 0 7.96042 0C9.07573 0 10.1204 0.210981 11.0944 0.632944C12.0685 1.05491 12.9168 1.62861 13.6396 2.35405C14.3623 3.07951 14.933 3.92732 15.3516 4.89747C15.7703 5.86763 15.9796 6.90659 15.9796 8.01436C15.9796 9.12213 15.7686 10.1593 15.3467 11.126C14.9247 12.0926 14.354 12.939 13.6346 13.6654C12.9152 14.3917 12.0673 14.9624 11.0909 15.3774C10.1145 15.7925 9.07252 16 7.96503 16Z"
        fill="#4B4B4B"
      />
    </svg>
  );
};

export default Zoho;
