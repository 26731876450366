import { useContext } from "react";

import { ProjectContext } from "src/context/ProjectContext";

const useProjects = () => {
  const {
    projects,
    selectedProjectID,
    setSelectedProjectID,
    isProjectsFetching,
    policies
  } = useContext(ProjectContext);

  return {
    projects,
    selectedProjectId: selectedProjectID,
    isProjectsFetching,
    setSelectedProjectId: setSelectedProjectID,
    policies
  };
};

export default useProjects;
