import React, { useEffect, useState } from "react";
import {
  Box,
  Menu,
  MenuItem,
  Typography,
  ButtonBase,
  ListItemText,
} from "@mui/material";
import Networks from "src/assets/icons/networks";
import ArrowDown from "src/assets/icons/arrowDown";
import { useTheme } from "@mui/material/styles";
import Cookie from "js-cookie";
import Image from "next/image";
import apiConfig from "src/configs/api";
import { regions } from "src/utils/regions";
import { Region } from "src/types/regions";
import { regionGlobePath } from "src/configs/constants";
import { useDispatch } from "react-redux";
import { useRouter } from "next/router";

import { ucwords } from "src/configs/helper";
import { useRegion } from "src/hooks/useRegion";
import themeConfig from "src/configs/themeConfig";
import { useAuth } from "src/hooks/useAuth";
import { serviceMapping } from "src/utils/regions";

const RegionSelector: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { selectedRegion, setSelectedRegion } = useRegion();
  const { user, setUser } = useAuth();
  const [regions, setRegions] = useState<Region[]>([]);
  const router = useRouter();
  const theme = useTheme();

  useEffect(() => {
    const queryParams = router.query;
    const projectID = queryParams[apiConfig.projectId] as string;
    if (user && user.projects && user.projects[projectID]) {
      const regionsArray = Object.values(user.projects[projectID]);
      setRegions(regionsArray);
    }
  }, [router.query, user]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isRegionSelected = (region: Region): boolean => {
    const queryParams = router.query;
    const currentRegion = queryParams[apiConfig.currentRegion] as string;
    if (region.identifier === currentRegion) {
      return true;
    }

    return false;
  };

  return (
    <React.Fragment>
      <ButtonBase
        onClick={(event) => handleClick(event)}
        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
      >
        <Typography variant="body2" marginRight="8px" fontWeight={"bold"}>
          Region:{" "}
          {selectedRegion && selectedRegion.displayName
            ? ucwords(selectedRegion.displayName)
            : ""}
        </Typography>
        <ArrowDown style={{ marginRight: "8px" }} />
        <Networks />
      </ButtonBase>
      <Menu
        id="region-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{ style: { padding: 0 } }}
      >
        {regions
          ? regions.map((region, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  if (isRegionSelected(region)) {
                    handleClose();
                    return;
                  }
                  setSelectedRegion(region);
                  setUser(null);
                  Cookie.set(apiConfig.currentRegion, region.identifier);
                  handleClose();
                  router.push("/kubernetes");
                }}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  color:
                    selectedRegion &&
                    selectedRegion.identifier === region.identifier
                      ? "primary.main"
                      : "inherit",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }} gap={3}>
                  <Image
                    src={themeConfig.imageBasePath + regionGlobePath}
                    alt={"location"}
                    width={22}
                    height={22}
                  />
                  <ListItemText
                    primary={region.name}
                    primaryTypographyProps={{
                      style: {
                        fontWeight: 500,
                        color:
                          selectedRegion &&
                          selectedRegion.identifier === region.identifier
                            ? theme.palette.primary.main
                            : "inherit",
                      },
                    }}
                  />
                </Box>
                <Typography
                  variant="subtitle2"
                  sx={{
                    marginLeft: "8px",
                    color:
                      selectedRegion &&
                      selectedRegion.identifier === region.identifier
                        ? theme.palette.primary.main
                        : "inherit",
                  }}
                >
                  {region.identifier ? region.identifier : ""}
                </Typography>
              </MenuItem>
            ))
          : null}
      </Menu>
    </React.Fragment>
  );
};

export default RegionSelector;
