import { toast, ToastOptions, Id } from "react-toastify";

import { toastDuration } from "src/configs/constants";

/**
 * Updates a toast notification with a success message.
 *
 * @param toastId - The ID of the toast to update, can be string or number.
 * @param message - The message to display in the toast.
 * @param isLoading - Whether the toast should display a loading spinner.
 * @param duration - How long the toast should stay visible (in milliseconds). [From constants.ts]
 * @returns The ID of the updated toast or void.
 *
 * @example
 * ```tsx:
 * If need a loader
 *    const toastId = showToast('loading', 'Fetching details', true);
 * On success:
 *    showToast('success', 'Fetch successful', false, toastId);
 * On error:
 *    showToast('error', 'Failed to fetch', false, toastId);
 * ```
 * ```tsx:
 * No loader required
 *    showToast('error', 'Failed to make the change');
 *    showToast('success', 'Failed to make the change');
 *    showToast('info', 'Failed to make the change');
 *    showToast('warning', 'Failed to make the change');
 * ```
 */

type TypeOptions = "info" | "success" | "warning" | "error" | "loading";

const showToast = (
  type: TypeOptions,
  message: string,
  isLoading: boolean = false,
  toastId?: Id,
  duration?: number
): Id | undefined => {
  const commonOptions: ToastOptions = {
    autoClose: duration || toastDuration,
    closeOnClick: true,
  };

  if (type !== "loading" && toastId !== undefined) {
    toast.update(toastId, {
      ...commonOptions,
      render: message,
      type: type,
      isLoading: isLoading,
    });
  } else {
    switch (type) {
      case "success":
        return toast.success(message, commonOptions);
      case "info":
        return toast.info(message, commonOptions);
      case "warning":
        return toast.warn(message, commonOptions);
      case "error":
        return toast.error(message, commonOptions);
      case "loading":
        return toast.loading(message, { ...commonOptions, autoClose: false });
      default:
        return toast(message, commonOptions);
    }
  }
};

export default showToast;
