import { SVGProps } from "react";

const Cart = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width ? props.width : "18"}
      height={props.height ? props.height : "23"}
      viewBox="0 0 18 23"
      fill={props.fill ? props.fill : "none"}
      xmlns="http://www.w3.org/2000/svg"
      cursor={"pointer"}
      {...props}
    >
      <path
        d="M3.88726 5.3049C3.74615 3.62944 4.20324 2.18357 5.55597 1.07987C6.48671 0.320883 7.57909 -0.0427989 8.80197 0.00400534C10.2236 0.0583995 11.4014 0.616888 12.3136 1.66113C13.2285 2.70917 13.4921 3.94379 13.3815 5.31945C13.7319 5.31945 14.0565 5.28403 14.3692 5.32578C15.7093 5.50477 16.5864 6.43263 16.6586 7.75707C16.7732 9.86073 16.8686 11.965 16.9733 14.0693C17.0733 16.0787 17.1873 18.0875 17.2701 20.0976C17.3217 21.3594 16.5321 22.3575 15.2927 22.649C15.0707 22.7009 14.8356 22.7237 14.6064 22.7237C10.6297 22.7281 6.65298 22.7287 2.6763 22.7262C1.09238 22.7249 -0.0543216 21.6194 0.00198661 20.1077C0.0708814 18.2469 0.178198 16.388 0.270941 14.5285C0.362359 12.7006 0.455102 10.8721 0.54917 9.04419C0.575668 8.52428 0.590242 8.00311 0.643238 7.4851C0.764467 6.29855 1.8383 5.34918 3.08768 5.30617C3.3414 5.29731 3.59578 5.3049 3.88792 5.3049H3.88726ZM5.46654 5.28466H11.8102C11.8102 5.01649 11.8128 4.76476 11.8102 4.51303C11.7936 3.07158 10.6873 1.80913 9.22727 1.56436C7.69701 1.30757 6.22835 2.08869 5.67057 3.44855C5.42878 4.03929 5.44931 4.65217 5.4672 5.28466H5.46654ZM4.95711 7.71849C4.52387 7.71532 4.1688 8.04865 4.1635 8.46419C4.15754 8.88037 4.50333 9.22444 4.93393 9.2314C5.37843 9.23836 5.74742 8.89492 5.74675 8.47494C5.74609 8.06572 5.38704 7.72165 4.95777 7.71849H4.95711ZM13.1052 8.48886C13.1099 8.07015 12.77 7.72924 12.3368 7.71912C11.8983 7.70837 11.5253 8.05497 11.522 8.47494C11.5187 8.881 11.8817 9.2295 12.3096 9.23203C12.7475 9.23393 13.1006 8.90504 13.1052 8.48949V8.48886Z"
        fill="#757575"
      />
    </svg>
  );
};

export default Cart;
