/**
 * Config
 * -------------------------------------------------------------------------------------
 * ! IMPORTANT: Make sure you clear the browser local storage in order to see the config changes in the template.
 * ! To clear local storage, you may refer https://www.leadshook.com/help/how-to-clear-local-storage-in-google-chrome-browser/.
 */

// ** React Imports
import { ReactNode, ReactSVGElement } from "react";

// ** MUI Imports
import { PaletteMode, Direction } from "@mui/material";

// ** Types
import {
  Skin,
  AppBar,
  Footer,
  ContentWidth,
  VerticalNavToggle,
  HorizontalMenuToggle,
} from "src/@core/layouts/types";
import CircleOutline from "mdi-material-ui/CircleOutline";
import appConfig from "src/configs/app";

type ThemeConfig = {
  skin: Skin;
  appBar: AppBar;
  footer: Footer;
  mode: PaletteMode;
  navHidden: boolean;
  appBarBlur: boolean;
  direction: Direction;
  templateName: string;
  navCollapsed: boolean;
  k8NavCollapsed: boolean;
  routingLoader: boolean;
  disableRipple: boolean;
  navigationSize: number;
  k8NavigationSize: number;
  navigationSizeFigma: number;
  menuTextTruncate: boolean;
  imageBasePath: string;
  contentWidth: ContentWidth;
  disableCustomizer: boolean;
  responsiveFontSizes: boolean;
  collapsedNavigationSize: number;
  collapsedNavigationSizeFigma: number;
  collapseK8NavigationSize: number;
  horizontalMenuAnimation: boolean;
  layout: "vertical" | "horizontal";
  verticalNavToggleType: VerticalNavToggle;
  horizontalMenuToggle: HorizontalMenuToggle;
  toastPosition:
    | "top-left"
    | "top-center"
    | "top-right"
    | "bottom-left"
    | "bottom-center"
    | "bottom-right";
  appLogoPath: string;
  appLogoAlternativePath: string;
  favIconPath: string;
  selectArrow: string;
  // navSubItemIcon:ReactNode,
};

const themeConfig: ThemeConfig = {
  // ** Layout Configs
  templateName:
    process.env.NEXT_PUBLIC_TEMPLATENAME &&
    process.env.NEXT_PUBLIC_TEMPLATENAME.trim() &&
    process.env.NEXT_PUBLIC_TEMPLATENAME.trim().length > 0
      ? process.env.NEXT_PUBLIC_TEMPLATENAME.trim()
      : "AceCloud" /* App Name */,
  imageBasePath: appConfig.basePath,
  favIconPath: appConfig.basePath + appConfig.favIconPath,
  appLogoPath: appConfig.basePath + appConfig.logoPath,
  layout: "vertical" /* vertical | horizontal */,
  appLogoAlternativePath: appConfig.basePath + "/images/aceShieldLogo.png",
  mode: "light" /* light | dark */,
  direction: "ltr" /* ltr | rtl */,
  skin: "bordered" /* default | bordered | semi-dark /*! Note: semi-dark value will only work for Vertical Layout */,
  contentWidth: "full" /* full | boxed */,
  footer: "static",
  selectArrow:
    appConfig.basePath +
    "/images/dropdownArrow.svg" /* fixed | static | hidden */,

  // ** Routing Configs
  routingLoader: true /* true | false */,

  // ** Navigation (Menu) Configs
  navHidden: false /* true | false */,
  menuTextTruncate: true /* true | false */,

  k8NavCollapsed: true,
  k8NavigationSize: 180,
  collapseK8NavigationSize: 0,
  verticalNavToggleType:
    "accordion" /* accordion | collapse /*! Note: This is for Vertical navigation menu only */,
  navCollapsed:
    false /* true | false /*! Note: This is for Vertical navigation menu only */,
  navigationSize: 225 /* Number in PX(Pixels) /*! Note: This is for Vertical navigation menu only */,
  collapsedNavigationSize: 68 /* Number in PX(Pixels) /*! Note: This is for Vertical navigation menu only */,
  navigationSizeFigma: 194,
  collapsedNavigationSizeFigma: 41,
  horizontalMenuToggle:
    "hover" /* click | hover /*! Note: This is for Horizontal navigation menu only */,
  horizontalMenuAnimation: true /* true | false */,

  // ** AppBar Configs
  appBar:
    "static" /* fixed | static | hidden /*! Note: hidden value will only work for Vertical Layout */,
  appBarBlur: true /* true | false */,

  // ** Other Configs
  responsiveFontSizes: true /* true | false */,
  disableRipple: false /* true | false */,
  disableCustomizer:
    process.env.NEXT_PUBLIC_CUSTOMIZER &&
    process.env.NEXT_PUBLIC_CUSTOMIZER.toLowerCase() === "on"
      ? false
      : true /* true | false */,
  toastPosition:
    "bottom-left" /* top-left | top-center | top-right | bottom-left | bottom-center | bottom-right */,
};

export default themeConfig;
