import { SVGProps } from "react";

const Password = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width ? props.width : "13"}
      height={props.height ? props.height : "16"}
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.14286 16C0.828571 16 0.559524 15.8881 0.335714 15.6643C0.111905 15.4405 0 15.1714 0 14.8571V6.59048C0 6.27619 0.111905 6.00714 0.335714 5.78333C0.559524 5.55952 0.828571 5.44762 1.14286 5.44762H2.47619V3.61905C2.47619 2.61778 2.82924 1.76429 3.53535 1.05857C4.24146 0.352857 5.09543 0 6.09726 0C7.09909 0 7.95238 0.352857 8.65714 1.05857C9.36191 1.76429 9.71429 2.61778 9.71429 3.61905V5.44762H11.0476C11.3619 5.44762 11.631 5.55952 11.8548 5.78333C12.0786 6.00714 12.1905 6.27619 12.1905 6.59048V14.8571C12.1905 15.1714 12.0786 15.4405 11.8548 15.6643C11.631 15.8881 11.3619 16 11.0476 16H1.14286ZM1.14286 14.8571H11.0476V6.59048H1.14286V14.8571ZM6.09844 12.1905C6.50265 12.1905 6.84762 12.0506 7.13333 11.7708C7.41905 11.4911 7.5619 11.1548 7.5619 10.7619C7.5619 10.381 7.41798 10.0349 7.13013 9.72381C6.84229 9.4127 6.49625 9.25714 6.09204 9.25714C5.68782 9.25714 5.34286 9.4127 5.05714 9.72381C4.77143 10.0349 4.62857 10.3841 4.62857 10.7714C4.62857 11.1587 4.7725 11.4921 5.06034 11.7714C5.34819 12.0508 5.69422 12.1905 6.09844 12.1905ZM3.61905 5.44762H8.57143V3.61905C8.57143 2.93121 8.33091 2.34656 7.84987 1.86509C7.36881 1.3836 6.78469 1.14286 6.09749 1.14286C5.41027 1.14286 4.8254 1.3836 4.34286 1.86509C3.86032 2.34656 3.61905 2.93121 3.61905 3.61905V5.44762Z"
        fill="#757575"
        className="grey-100-fill"
      ></path>
    </svg>
  );
};

export default Password;
