import Axios, { AxiosError } from "axios";
import Cookie from "js-cookie";

import Router from "next/router";

import { getRoute, getRouteWithParams } from "src/routes";
import apiConfig from "src/configs/api";
import {
  BASEURL,
  FRONTEND_BASE_PATH,
  REGION_UNAVAILABLE,
} from "src/configs/constants";
import {
  PROJECT_UNAVAILABLE,
  ProjectNotFound,
  TIMEOUT_ERROR_MESSAGE,
} from "src/language/en/message";
import showToast from "src/configs/notifications";
import appConfig from "src/configs/app";

const axios = Axios.create({
  baseURL: BASEURL,
  timeout: 1000 * 30,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
axios.interceptors.request.use(
  (req) => {
    if (req?.params?.useFrontendBasePath) {
      req.baseURL = FRONTEND_BASE_PATH.endsWith(appConfig.basePath)
        ? FRONTEND_BASE_PATH.slice(0, -appConfig.basePath.length)
        : FRONTEND_BASE_PATH;
    }

    if (["/auth/login"].includes(String(req.url).toLowerCase())) {
      const userRemember = Cookie.get(apiConfig.userRemember);
      if (userRemember && req.headers) {
        req.headers["Whmcs-UserRemember"] = userRemember;
      }
      return req;
    }
    if (req.headers) {
      req.headers.Authorization = `Bearer ${localStorage.getItem(apiConfig.storageTokenKeyName)}`;
    }

    return req;
  },
  async (error) => {
    throw error;
  },
);
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (
      error &&
      error.code === "ECONNABORTED" &&
      error.message.includes("timeout")
    ) {
      showToast("error", TIMEOUT_ERROR_MESSAGE);
      return;
    }
    if (
      error.message === "Network Error" ||
      (error.response &&
        error.response.data &&
        error.response.data.message &&
        typeof error.response.data.message === "string" &&
        error.response.status === 401 &&
        (error.response.data.message.toLowerCase() === "unauthorized" ||
          error.response.data.message.toLowerCase() === ProjectNotFound))
    ) {
      if (error.message === "Network Error") {
        // toast.error('Network Error')

        // return  Router.push('/')

        return;
      }
      if (
        typeof error.response.data.message === "string" &&
        error.response.data.message.toLowerCase() === ProjectNotFound
      ) {
        return Router.push(getRouteWithParams("selectRegion"));
      }

      localStorage.removeItem(apiConfig.storageTokenKeyName);
      await Router.push(getRoute("logout"));
      throw new AxiosError("Unauthorized");
    } else if (
      error.response &&
      error.response.status === 401 &&
      error.response.data &&
      error.response.data.message &&
      error.response.data.message === REGION_UNAVAILABLE
    ) {
      await Router.push(getRouteWithParams("serviceNotAvailable"));
    } else if (
      error.response &&
      error.response.status === 400 &&
      error.response?.data?.message &&
      error.response.data.message === PROJECT_UNAVAILABLE
    ) {
      await Router.push(getRouteWithParams("getStarted"));
    }

    throw error;
  },
);
export default axios;
