// src/contexts/RegionContext.tsx
import { useRouter } from "next/router";
import React, { createContext, useState, ReactNode, useEffect } from "react";
import apiConfig from "src/configs/api";
import { defaultRegion } from "src/configs/constants";
import useQueryParams from "src/hooks/useQueryParams";
import { Region } from "src/types/regions";
import { useAuth } from "src/hooks/useAuth";
import { regions as availableRegions } from "src/utils/regions";
import { getRoute } from "src/routes";

interface RegionContextType {
  selectedRegion: Region | null;
  setSelectedRegion: (region: Region) => void;
  currentRegion: string;
}

interface RegionProviderProps {
  children: ReactNode;
}

export const RegionContext = createContext<RegionContextType | undefined>(
  undefined,
);

export const RegionProvider: React.FC<RegionProviderProps> = ({ children }) => {
  const { user } = useAuth();
  const queryParams = useQueryParams();
  const router = useRouter();
  const regionValue = queryParams[apiConfig.currentRegion] || defaultRegion;
  const projectID = queryParams[apiConfig.projectId] as string;
  const projectRegions = user?.projects?.[projectID] 
  ? Object.values(user.projects[projectID])
  : [];
  let selected:Region;
  if(projectRegions.length > 0) {
    const matchingProjectRegion = projectRegions.find(
      (region: Region) => region.identifier === regionValue
    );
    selected = matchingProjectRegion || projectRegions[0];
  } else {
    const matchingAvailableRegion = availableRegions.find(
      availableRegion => availableRegion.identifier === regionValue
    );
    selected = matchingAvailableRegion || availableRegions[0];
  }
  
  const [selectedRegion, setSelectedRegion] = useState<Region>(
    selected || user?.projects[projectID][Object.keys(user?.projects[projectID])[0]],
  );
  let currentRegion =
    selectedRegion && selectedRegion.displayName
      ? selectedRegion.displayName
      : user?.projects[projectID][Object.keys(user?.projects[projectID])[0]].displayName;

  currentRegion = currentRegion ?? ''

  useEffect(() => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (
    selectedRegion && queryParams[apiConfig.currentRegion] !== selectedRegion.identifier
    ) {
      if(selectedRegion.is_accessible === false) {
        router.push({
          pathname: getRoute("getStarted"),
          query: {
            ...router.query,
            ...(selectedRegion ? { [apiConfig.currentRegion]: selectedRegion.identifier } : {}),

          }
        },undefined,
        { shallow: true },
      )
      } else  {
        router.push(
          {
            pathname: router.pathname,
            query: {
              ...router.query,
              ...(selectedRegion ? { [apiConfig.currentRegion]: selectedRegion.identifier } : {}),
              ...(!queryParams[apiConfig.timezone] ? {[apiConfig.timezone]: timezone} : {})
            }
          },
          undefined,
          { shallow: true },
        );
      }
    } 
     else if (!queryParams[apiConfig.timezone]) {
        router.push(
          {
            pathname: router.pathname,
            query: {
              ...router.query,
              [apiConfig.timezone]: timezone,
            },
          },
          undefined,
          { shallow: true },
        );
      }

   
  }, []);

  return (
    <RegionContext.Provider
      value={{ selectedRegion, setSelectedRegion, currentRegion }}
    >
      {children}
    </RegionContext.Provider>
  );
};
