import { SVGProps } from "react";

const Database = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width ?? "16"}
      height={props.height ?? "16"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* Top cylinder */}
      <path
        d="M8 1C4.13401 1 1 2.11929 1 3.5V5.5C1 6.88071 4.13401 8 8 8C11.866 8 15 6.88071 15 5.5V3.5C15 2.11929 11.866 1 8 1Z"
        fill={props.color ?? "#4B4B4B"}
      />
      
      {/* Middle section */}
      <path
        d="M15 7.5C15 8.88071 11.866 10 8 10C4.13401 10 1 8.88071 1 7.5"
        stroke={props.color ?? "#4B4B4B"}
        strokeWidth="2"
      />
      
      {/* Bottom section */}
      <path
        d="M15 11.5C15 12.8807 11.866 14 8 14C4.13401 14 1 12.8807 1 11.5"
        stroke={props.color ?? "#4B4B4B"}
        strokeWidth="2"
      />
      
      {/* Vertical lines */}
      <path
        d="M1 3.5V12.5M15 3.5V12.5"
        stroke={props.color ?? "#4B4B4B"}
        strokeWidth="2"
      />
    </svg>
  );
};

export default Database;