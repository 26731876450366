import { useContext } from "react";

import { KYCContext } from "src/context/KYCContext";

const useKYCStatus = () => {
  const { kycStatus, userDetails, isKYCFetching, trialStatus, setRefreshKYCStatus } = useContext(KYCContext);

  return { kycStatus, userDetails, isKYCFetching, trialStatus, setRefreshKYCStatus };
};

export default useKYCStatus;
