import * as yup from "yup";

export const loginResponseSchema = yup.object().shape({
  accessToken: yup.string().required(),
  email: yup.string().required(),
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  type: yup.string().required(),
  whmcsCookie: yup.array().of(yup.string().required()).required(),
});
