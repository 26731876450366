const S3 = () => {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        d="M3.56575 2.75029C3.30705 2.75029 3.08788 2.84084 2.90825 3.02194C2.72861 3.20302 2.63879 3.42292 2.63879 3.68163C2.63879 3.94034 2.72934 4.1595 2.91044 4.33914C3.09152 4.51878 3.31142 4.60859 3.57014 4.60859C3.82884 4.60859 4.04801 4.51805 4.22764 4.33695C4.40728 4.15586 4.4971 3.93596 4.4971 3.67725C4.4971 3.41855 4.40655 3.19938 4.22545 3.01974C4.04436 2.84011 3.82446 2.75029 3.56575 2.75029ZM3.56575 11.3914C3.30705 11.3914 3.08788 11.482 2.90825 11.6631C2.72861 11.8441 2.63879 12.064 2.63879 12.3228C2.63879 12.5815 2.72934 12.8006 2.91044 12.9803C3.09152 13.1599 3.31142 13.2497 3.57014 13.2497C3.82884 13.2497 4.04801 13.1592 4.22764 12.9781C4.40728 12.797 4.4971 12.5771 4.4971 12.3184C4.4971 12.0597 4.40655 11.8405 4.22545 11.6609C4.04436 11.4812 3.82446 11.3914 3.56575 11.3914ZM0.947735 0H13.3426C13.6356 0 13.873 0.0929152 14.0548 0.278746C14.2366 0.464576 14.3275 0.708094 14.3275 1.0093V6.20674C14.3275 6.53318 14.2366 6.80681 14.0548 7.02764C13.873 7.24847 13.6356 7.35888 13.3426 7.35888H0.947735C0.665583 7.35888 0.437092 7.24847 0.262262 7.02764C0.0874208 6.80681 0 6.53318 0 6.20674V1.0093C0 0.708094 0.0874208 0.464576 0.262262 0.278746C0.437092 0.0929152 0.665583 0 0.947735 0ZM1.74681 1.76539V5.61208H12.5621V1.76539H1.74681ZM0.947735 8.62253H13.2683C13.5489 8.62253 13.7956 8.73914 14.0084 8.97236C14.2211 9.20557 14.3275 9.47938 14.3275 9.79377V14.9036C14.3275 15.253 14.2211 15.523 14.0084 15.7138C13.7956 15.9046 13.5489 16 13.2683 16H1.04065C0.746109 16 0.499035 15.9046 0.299429 15.7138C0.0998095 15.523 0 15.253 0 14.9036V9.79377C0 9.47938 0.0874208 9.20557 0.262262 8.97236C0.437092 8.73914 0.665583 8.62253 0.947735 8.62253ZM1.74681 10.3879V14.2346H12.5621V10.3879H1.74681Z"
        fill="#4B4B4B"
      />
    </svg>
  );
};

export default S3;
