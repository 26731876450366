import { CommonPrefix } from "@aws-sdk/client-s3";
import SparkMD5 from "spark-md5";

import { NextApiRequest } from "next/types";

import { ServiceProviderConfigType } from "src/types/S3/service-provider-config";
import {
  INVALID_REGION_RESPONSE,
  REGION_REQUIRED_RESPONSE,
} from "src/language/en/message";
import { S3ObjectStorageMessages } from "src/language/en/objectStorage/S3.en";
import { ContentType } from "src/types/S3/FileType";
import {
  customDecodeURIComponent,
  getParsedInputTypes,
} from "src/configs/helper";
import {
  cephS3ServiceConfig,
  regionProviderMapping,
  activeScaleS3ServiceConfig,
  allowedPolicyConditions,
  allowedPolicyConditionKeys,
  allowedPolicyActions,
} from "src/utils/S3/S3Constants";
import appConfig from "src/configs/app";
import { errorLogInSentry } from "src/store/sentry";
import { TABLE_NULL_PLACEHOLDER } from "src/configs/constants";

interface AccessOutput {
  [key: string]: Array<"Read" | "Write">;
}

export const getAccessKeyFromRequest = (request: NextApiRequest): string => {
  let S3AccessKey = request.headers["x-custom-access-key"];
  return S3AccessKey ? decodeURIComponent(S3AccessKey as string) : "";
};

export const getSecretKeyFromRequest = (request: NextApiRequest): string => {
  let S3SecretKey = request.headers["x-custom-secret-key"];
  return S3SecretKey ? decodeURIComponent(S3SecretKey as string) : "";
};

export const getS3ServiceProviderConfig = (
  region: string,
): ServiceProviderConfigType => {
  try {
    if (!region) {
      throw new Error(REGION_REQUIRED_RESPONSE);
    }
    const provider = regionProviderMapping[region];
    if (!provider) {
      throw new Error(INVALID_REGION_RESPONSE);
    }
    let S3ServiceConfig = undefined;
    switch (provider) {
      case "ceph": {
        S3ServiceConfig = cephS3ServiceConfig;
        break;
      }
      case "activescale": {
        S3ServiceConfig = activeScaleS3ServiceConfig;
        break;
      }
      default: {
        throw new Error(INVALID_REGION_RESPONSE);
      }
    }
    return S3ServiceConfig;
  } catch (error) {
    throw error;
  }
};

export const percentEncode = (input: string): string => {
  return input
    ? encodeURIComponent(decodeURIComponent(input))
        .replace(
          /[!'()*]/g,
          (char) => "%" + char.charCodeAt(0).toString(16).toUpperCase(),
        )
        .replace(
          /[-.~]/g,
          (char) => "%" + char.charCodeAt(0).toString(16).toUpperCase(),
        )
        .replace(/"/g, (char) => "%22")
        .replace(/'/g, (char) => "%27")
        .replace(/`/g, (char) => "%60")
    : input;
};

export const percentDecode = (input: string): string => {
  if (!input) {
    return input;
  }

  return decodeURIComponent(input.replace(/%(?![0-9a-fA-F]{2})/g, "%25"))
    .replace(/\+/g, " ")
    .replace(/%27/g, "'")
    .replace(/%22/g, '"')
    .replace(/%60/g, "`");
};

export const isOnLastPage = (
  pagination: { pageSize: number; pageIndex: number },
  objectListLength: number,
): boolean => {
  if (!pagination.pageSize) {
    return false;
  }
  const totalPages = Math.ceil(objectListLength / pagination.pageSize);
  return pagination.pageIndex === totalPages - 1;
};

export const appendCommonPrefixes = (
  contents: ContentType[],
  commonPrefix: CommonPrefix,
  isVersioning: boolean,
): ContentType[] => {
  if (!commonPrefix?.Prefix) {
    return contents;
  } else {
    const commonPrefixObject: ContentType = {
      Key: commonPrefix.Prefix,
      LastModified: "",
      ETag: "",
      Size: 0,
      StorageClass: "",
    };
    if (isVersioning) {
      commonPrefixObject["VersionId"] = "";
    }
    return [commonPrefixObject, ...contents];
  }
};

export const decodeKeyFileName = (key: string, prefixString: string) => {
  let decodedKey = customDecodeURIComponent(key.replace(/\+/g, " "));
  if (decodedKey.startsWith(prefixString)) {
    decodedKey = decodedKey.substring(prefixString.length);
  }
  return customDecodeURIComponent(decodedKey);
};

export const getBucketPolicySchema = (bucketName: string) => {
  return {
    type: "object",
    properties: {
      Version: { type: "string" },
      Id: { type: "string" },
      Statement: {
        type: "array",
        items: {
          type: "object",
          properties: {
            Sid: { type: "string" },
            Effect: { type: "string", enum: ["Allow", "Deny"] },
            Action: {
              oneOf: [
                {
                  type: "string", // Single action
                  pattern: "^(\\*|[^,]+)$", // Allow "*" or any other string without commas
                  errorMessage: "Action cannot be a comma-separated string",
                  enum: [...allowedPolicyActions, "*"], // Include "*" in the allowed actions
                },
                {
                  type: "array", // Array of actions
                  items: {
                    type: "string",
                    pattern: "^(\\*|[^,]+)$", // Allow "*" or any string without commas
                    errorMessage: "Each action cannot contain commas",
                    enum: [...allowedPolicyActions, "*"], // Include "*" in allowed actions
                    uniqueItems: true, // Ensure no duplicate actions
                  },
                },
              ],
            },
            Resource: {
              oneOf: [
                {
                  type: "string",
                  // Allow * or ARN pattern with bucket name and support folder structure and file extensions
                  pattern: `^(\\*|arn:aws:s3:::${bucketName}(?:/[a-zA-Z0-9-_.\\/]*\\*?)?)$`,
                  errorMessage: `Resource must be either '*' or 'arn:aws:s3:::${bucketName}/*' and allow subfolders and files with extensions.`,
                },
                {
                  type: "array",
                  items: {
                    type: "string",
                    // Same validation for array items
                    pattern: `^(\\*|arn:aws:s3:::${bucketName}(?:/[a-zA-Z0-9-_.\\/]*\\*?)?)$`,
                    errorMessage: `Each Resource item must be either '*' or 'arn:aws:s3:::${bucketName}/*' and allow subfolders and files with extensions.`,
                  },
                  uniqueItems: true,
                },
              ],
            },
            Principal: {
              oneOf: [
                {
                  type: "string",
                  enum: ["*"], // Allow "*" as a valid string value
                  errorMessage: "Principal must be '*' or a valid AWS account",
                },
                {
                  type: "object",
                  properties: {
                    AWS: {
                      oneOf: [
                        {
                          type: "string",
                          minLength: 1, // Ensure AWS has some value as a string
                          errorMessage:
                            "AWS account or ARN must be a non-empty string",
                        },
                        {
                          type: "array",
                          items: {
                            type: "string",
                            minLength: 1, // Ensure each AWS account or ARN is a non-empty string
                            errorMessage:
                              "Each AWS account or ARN must be a non-empty string",
                          },
                          uniqueItems: true, // Ensure no duplicate ARNs/accounts in the array
                        },
                      ],
                      errorMessage:
                        "AWS must be a non-empty string or an array of non-empty strings",
                    },
                  },
                  required: ["AWS"], // Require the AWS field in the object
                  additionalProperties: false,
                },
              ],
              errorMessage: "Principal cannot be an empty string or object",
            },
            Condition: {
              type: "object",
              patternProperties: {
                [`^(${allowedPolicyConditions.join("|")})`]: {
                  type: "object",
                  patternProperties: {
                    [`^(${allowedPolicyConditionKeys.join("|")})$`]: {
                      oneOf: [
                        {
                          type: "string", // Single action
                          pattern: "^(\\*|[^,]+)$", // Allow "*" or any other string without commas
                          errorMessage:
                            "Action cannot be a comma-separated string",
                        },
                        {
                          type: "array", // Array of actions
                          items: {
                            type: "string",
                            pattern: "^(\\*|[^,]+)$", // Allow "*" or any string without commas
                            errorMessage: "Each action cannot contain commas",
                            uniqueItems: true, // Ensure no duplicate actions
                          },
                        },
                      ],
                    },
                  },
                  additionalProperties: false,
                },
              },
              additionalProperties: false,
            },
          },
          required: ["Sid", "Effect", "Action", "Resource", "Principal"],
          additionalProperties: false,
        },
      },
    },
    required: ["Version", "Statement"],
    additionalProperties: false,
  };
};

export const getObjectType: (objectName: string) => string = (objectName) => {
  return objectName.includes("/") ? "folder" : "file";
};

const suffixMap = {
  IA: "Infrequent Access",
  IR: "Instant Retrieval",
};

/**
 * Formats storage class for display
 * @param storageClass - Storage class string
 * @returns Formatted storage class string
 */
export const formatStorageClass = (storageClass: string): string => {
  if (!storageClass) {
    return TABLE_NULL_PLACEHOLDER;
  }
  const words = storageClass.split("_");
  return words
    .map((word, index) => {
      if (
        index === words.length - 1 &&
        suffixMap[word as keyof typeof suffixMap]
      ) {
        return suffixMap[word as keyof typeof suffixMap];
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
};

/**
 * Gets storage class options based on the S3 provider
 * @param region - Current region from route
 * @returns Object containing storage class options
 */
export const getStorageClassOptions = (
  region: string,
): { [key: string]: string } => {
  try {
    if (!region) {
      throw new Error(REGION_REQUIRED_RESPONSE);
    }
    const provider = regionProviderMapping[region];
    if (!provider) {
      throw new Error(INVALID_REGION_RESPONSE);
    }

    let storageClasses: string[];

    if (
      provider &&
      typeof provider === "string" &&
      provider.toLowerCase() === "ceph"
    ) {
      if (!appConfig.allowedCephStorageClasses) {
        throw new Error(S3ObjectStorageMessages.storageClassesNotConfigured);
      }
      storageClasses = appConfig.allowedCephStorageClasses
        .split(",")
        .map((s) => s.trim());
    } else if (
      provider &&
      typeof provider === "string" &&
      provider.toLowerCase() === "activescale"
    ) {
      if (!appConfig.allowedActiveScaleStorageClasses) {
        throw new Error(S3ObjectStorageMessages.storageClassesNotConfigured);
      }
      storageClasses = appConfig.allowedActiveScaleStorageClasses
        .split(",")
        .map((s) => s.trim());
    } else {
      throw new Error(S3ObjectStorageMessages.unsupportedProvider);
    }

    return storageClasses.reduce(
      (acc, storageClass) => {
        acc[storageClass] = formatStorageClass(storageClass);
        return acc;
      },
      {} as { [key: string]: string },
    );
  } catch (error) {
    errorLogInSentry(
      error,
      {},
      "LIST_STORAGE_CLASS",
      "getStorageClassOptions",
      "src/utils/S3/S3Helper.ts",
    );
    return { STANDARD: "Standard" };
  }
};

function numberToBase36(num: number) {
  if (num < 0) throw new Error("Number must be non-negative");

  const chars = "abcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";

  do {
    result = chars[num % 36] + result;
    num = Math.floor(num / 36);
  } while (num > 0);

  return result;
}

export const getUniqueBucketName = (firstName?: string, lastName?: string) => {
  const lowerCaseFirstName = firstName ? firstName.toLowerCase() + "-" : "";
  const lowerCaseLastName = lastName ? lastName.toLowerCase() + "-" : "";
  return lowerCaseFirstName + lowerCaseLastName + numberToBase36(Date.now());
};

export const getFileTypeWarning = () => {
  const allowedFileTypes = getParsedInputTypes();

  // Format file types to be more user-friendly
  const formattedTypes = allowedFileTypes
    .map((type) => {
      // Handle special cases
      if (type === "text/plain") return "txt";
      return type;
    })
    .map((type) => `.${type}`); // Add dot prefix

  return `Please upload a file in one of these supported formats: ${formattedTypes.join(", ")}.`;
};
/**
 * Computes Content-MD5 hash for S3 uploads
 * @param data - The data to compute MD5 hash for
 * @returns Promise<string> - Base64 encoded MD5 hash
 */
export const computeContentMD5 = async (data: File | Blob): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = (event: ProgressEvent<FileReader>) => {
      try {
        const arrayBuffer = event.target?.result as ArrayBuffer;
        const spark = new SparkMD5.ArrayBuffer();
        spark.append(arrayBuffer);
        const hash = spark.end(true); // true means binary
        resolve(btoa(hash)); // Convert binary string to base64
      } catch (error) {
        reject(new Error("Failed to compute MD5 hash"));
      }
    };

    fileReader.onerror = () => {
      reject(new Error("Failed to read file"));
    };

    fileReader.readAsArrayBuffer(data);
  }).catch((error) => {
    throw new Error(`Failed to compute Content-MD5: ${error}`);
  });
};
