// ** React Imports
import { ReactNode, useEffect } from "react";

// ** MUI Imports
import { styled, useTheme } from "@mui/material/styles";
import MuiSwipeableDrawer, {
  SwipeableDrawerProps,
} from "@mui/material/SwipeableDrawer";

// ** Type Import
import { Settings } from "src/@core/context/settingsContext";

interface Props {
  hidden: boolean;
  navWidth: number;
  navHover: boolean;
  settings: Settings;
  navVisible: boolean;
  children: ReactNode;
  collapsedNavWidth: number;
  navigationBorderWidth: number;
  setNavHover: (values: boolean) => void;
  setNavVisible: (value: boolean) => void;
  saveSettings: (values: Settings) => void;
}

const SwipeableDrawer = styled(MuiSwipeableDrawer)<SwipeableDrawerProps>({
  overflowX: "hidden",
  height: "inherit",
  transition: "width .25s ease-in-out",

  "& ul": {
    listStyle: "none",
  },
  "& .MuiListItem-gutters": {
    paddingLeft: 2,
    paddingRight: 2,
  },
  "& .MuiDrawer-paper": {
    left: "unset",
    right: "unset",
    overflowX: "hidden",
    transition: "width .25s ease-in-out, box-shadow .25s ease-in-out",
    position: "relative",
  },
});

const Drawer = (props: Props) => {
  // ** Props
  const {
    hidden,
    children,
    navHover,
    navWidth,
    settings,
    navVisible,
    setNavHover,
    saveSettings,
    setNavVisible,
    collapsedNavWidth,
    navigationBorderWidth,
  } = props;

  // ** Hook
  const theme = useTheme();

  // ** Vars
  const { skin, navCollapsed } = settings;

  useEffect(() => {
    if (navCollapsed && hidden && settings) {
      saveSettings({ ...settings, navCollapsed: false });
    } else if (settings) {
      saveSettings({ ...settings });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hidden]);

  const drawerColor = () => {
    if (skin === "semi-dark" && theme.palette.mode === "light") {
      return {
        "& .MuiTypography-root, & .MuiSvgIcon-root": {
          color: `rgba(${theme.palette.customColors.dark}, 0.87)`,
        },
      };
    } else if (skin === "semi-dark" && theme.palette.mode === "dark") {
      return {
        "& .MuiTypography-root, & .MuiSvgIcon-root": {
          color: `rgba(${theme.palette.customColors.light}, 0.87)`,
        },
      };
    } else return {};
  };

  const drawerBgColor = () => {
    if (skin === "semi-dark" && theme.palette.mode === "light") {
      return {
        backgroundColor: theme.palette.customColors.darkBg,
      };
    } else if (skin === "semi-dark" && theme.palette.mode === "dark") {
      return {
        backgroundColor: theme.palette.customColors.lightBg,
      };
    } else {
      return {
        backgroundColor: theme.palette.background.default,
      };
    }
  };

  // Drawer Props for Mobile & Tablet screens
  const MobileDrawerProps = {
    open: navVisible,
    onOpen: () => setNavVisible(true),
    onClose: () => setNavVisible(false),
    ModalProps: {
      keepMounted: true, // Better open performance on mobile.
    },
  };

  // Drawer Props for Desktop screens
  const DesktopDrawerProps = {
    open: true,
    onOpen: () => null,
    onClose: () => null,
    onMouseEnter: () => {
      setNavHover(true);
    },
    onMouseLeave: () => {
      setNavHover(false);
    },
  };

  return (
    <SwipeableDrawer
      variant={hidden ? "temporary" : "permanent"}
      {...(hidden ? { ...MobileDrawerProps } : { ...DesktopDrawerProps })}
      PaperProps={{
        sx: { width: navCollapsed && !navHover ? collapsedNavWidth : navWidth },
      }}
      sx={{
        width: navCollapsed && !navHover ? collapsedNavWidth : navWidth,
        "& .MuiDrawer-paper": {
          ...drawerColor(),
          ...drawerBgColor(),
          ...(!hidden && navCollapsed && navHover ? { boxShadow: 9 } : {}),
          borderRight: `1px solid ${theme.palette.divider}`,
        },
      }}
    >
      {children}
    </SwipeableDrawer>
  );
};

export default Drawer;
