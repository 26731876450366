import appConfig from "src/configs/app";

export const S3ObjectStorageMessages = {
  bucketAlreadyExistsAccrossProjects:
    "The requested bucket name is not available. The bucket namespace is shared by all users of the system. Please select a different name and try again",
  bucketAlreadyExistsInProject:
    "Your previous request to create the named bucket succeeded and you already own it",
  downloadFailedErrorMessage:
    "Unable to download file. Please try copying the path instead",
  abortMultiPartUpload: "Multipart upload aborted successfully",
  failedAbortMultiPartUpload: "Failed to abort multipart upload",
  failedMultiPartUpload: "Failed to complete multipart upload",
  successMultiPartUploadInitiation: "Multipart upload initiated successfully",
  failedMuliPartUploadInitiation: "Failed to initiate multipart upload",
  failedUploadPart: "Failed to upload part",
  successFetchBuckets: "Buckets fetched successfully",
  failedFetchBuckets: "Failed to fetch buckets",
  accessCredentialDialogTitleHelper: `Download or copy your access credentials now, as they won't be
          available again`,
  uploadObjectHelperMessage: `For uploads exceeding ${appConfig.maxFileCount} objects, we highly recommend using options like S3 browser, AWS CLI, Cyberduck or other third-party tools.`,
  preSignedURLGenerationSuccess: "Pre-signed URL generated successfully",
  preSignedURLGenerationFailure: "Failed to generate pre-signed URL",
  abortFailed: "Failed to abort upload",
  uploadSuccess: "All file(s) uploaded successfully",
  uploadFailed: "Failed to upload file(s)",
  invalidFileNameMessage: `File name contains invalid characters. Please avoid using < > : " / \\ | ? * % + & #`,
  zeroFileSizeErrorMessage: "File size should be greater than 0",
  fileTypeNotAllowed: "File type not allowed",
  bucketCorsSetFailed: "Failed to set bucket CORS",
  objectUploadInitializationFailed:
    "Upload initialization failed. Please try again",
  objectUploadInitializtionSuccess: "Upload initiated successfully",
  validBucketMessage: "Bucket is valid",
  invalidBucketMessage: "Bucket does not exist",
  validObjectMessage: "Object is valid",
  invalidObjectMessage: "Object does not exist",
  addPolicyMessage:
    "No statements are currently defined in your policy. Click the button below to add your first statement",
  addResourceTitleHelperMessage:
    "Specify the resource type and ARN to add for the selected service",
  addConditionTitleHelperMessage:
    "Specify the condition key, operator and value to add for the selected service",
  invalidPolicyJsonMessage:
    "The policy JSON is invalid. Please ensure it follows the correct structure and syntax",
  bucketPolicyFetchFailed: "Failed to fetch bucket policy",
  bucketPolicyFetchSuccess: "Bucket policy fetched successfully",
  bucketPolicySetFailed: "Failed to set bucket policy",
  bucketPolicySetSuccess: "Bucket policy set successfully",
  bucketPolicyDeleteFailed: "Failed to delete bucket policy",
  bucketPolicyDeleteSuccess: "Bucket policy deleted successfully",
  savingBucketPolicy: "Saving bucket policy",
  storageClassesNotConfigured: "Storage classes are not configured",
  unsupportedProvider: "Unsupported S3 provider",
  storageClassTooltip:
    "Select a storage class that best meets your cost, performance, and durability needs",
  bucketVersioningFetchSuccess: "Successfully fetched bucket versioning",
  bucketVersioningUpdateSuccess:
    "Successfully updated bucket versioning status",
  bucketObjectLockingFetchSucces: "Successfully fetched object locking status",
  bucketObjectLockingUpdateSuccess:
    "Successfully updated object locking details",
  bucketNameHelperMessage: "Bucket names must be unique",
};
