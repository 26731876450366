// Note: S3 Context
import { StringMap } from "quill";
import { useState, createContext, ReactNode } from "react";

import { S3ValuesType } from "src/context/types";

const defaultProvider: S3ValuesType = {
  isLoading: false,
  setIsLoading: (value: boolean) => {},
  isS3AccountFound: false,
  setIsS3AccountFound: (value: boolean) => {},
  S3AccountKeys: { accessKey: "", secretKey: "" },
  setS3AccountKeys: (value: { accessKey: string; secretKey: string }) => {},
  s3AccountId: "",
  setS3AccountId: (Val: string) => {},
};

const S3Context = createContext<S3ValuesType>(defaultProvider);

interface Props {
  children: ReactNode;
}

const S3Provider = ({ children }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isS3AccountFound, setIsS3AccountFound] = useState<boolean>(false);
  const [S3AccountKeys, setS3AccountKeys] = useState<{
    accessKey: string;
    secretKey: string;
  }>({ accessKey: "", secretKey: "" });
  const [s3AccountId, setS3AccountId] = useState<string>("");

  return (
    <S3Context.Provider
      value={{
        isLoading,
        setIsLoading,
        isS3AccountFound,
        setIsS3AccountFound,
        S3AccountKeys,
        setS3AccountKeys,
        s3AccountId,
        setS3AccountId,
      }}
    >
      {children}
    </S3Context.Provider>
  );
};

export { S3Context, S3Provider };
