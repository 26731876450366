// hooks/useQueryParams.ts
import { useRouter } from "next/router";
import { useMemo } from "react";

type QueryParams = { [key: string]: string | string[] | undefined };

const useQueryParams = (): QueryParams => {
  const { query } = useRouter();

  // Using useMemo to memorize the query params object
  // This is useful for preventing unnecessary re-renders
  return useMemo(() => {
    // Create a shallow copy of the query params to ensure the router's query object is not mutated
    const params: QueryParams = {};
    Object.keys(query).forEach((key) => {
      params[key] = query[key];
    });
    return params;
  }, [query]);
};

export default useQueryParams;
