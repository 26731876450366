import { SVGProps } from "react";

const Logout = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width ? props.width : "15"}
      height={props.height ? props.height : "15"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33333 16C0.977778 16 0.666667 15.8667 0.4 15.6C0.133333 15.3333 0 15.0222 0 14.6667V1.33333C0 0.977778 0.133333 0.666667 0.4 0.4C0.666667 0.133333 0.977778 0 1.33333 0H7.8V1.33333H1.33333V14.6667H7.8V16H1.33333ZM12.1333 11.8889L11.1778 10.9333L13.4444 8.66667H5.66667V7.33333H13.4L11.1333 5.06667L12.0889 4.11111L16 8.02222L12.1333 11.8889Z"
        fill="var(--grey)"
      ></path>
    </svg>
  );
};

export default Logout;
