// ** React Import
import { ReactNode, useRef, useState } from "react";

// ** MUI Import
import List from "@mui/material/List";
import Box, { BoxProps } from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";

// ** Type Import
import { Settings } from "src/@core/context/settingsContext";
import { VerticalNavItemsType } from "src/@core/layouts/types";

// ** Component Imports
import Drawer from "./Drawer";

import VerticalNavHeader from "src/figmaLayout/navigation/VerticalNavHeader";
import VerticalNavItems from "src/figmaLayout/navigation/VerticalNavItems";
import BottomVerticalNavItems from "src/figmaLayout/navigation/BottomVerticalNavItems";
import Divider from "@mui/material/Divider";

interface Props {
  hidden: boolean;
  navWidth: number;
  navHover: boolean;
  settings: Settings;
  children: ReactNode;
  navVisible: boolean;
  collapsedNavWidth: number;
  navigationBorderWidth: number;
  toggleNavVisibility: () => void;
  setNavHover: (values: boolean) => void;
  setNavVisible: (value: boolean) => void;
  verticalNavItems?: VerticalNavItemsType;
  bottomVerticalNavItems?: VerticalNavItemsType;
  saveSettings: (values: Settings) => void;
  verticalNavMenuContent?: (props?: any) => ReactNode;
  afterVerticalNavMenuContent?: (props?: any) => ReactNode;
  beforeVerticalNavMenuContent?: (props?: any) => ReactNode;
}

const NavBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
  position: "relative",
  paddingLeft: "14px",
  marginTop: "64px",
  overflowY: "auto",
  overflowX: "hidden",
  scrollbarWidth: "none",
  "-ms-overflow-style": "none",
  "&::-webkit-scrollbar": {
    width: "0.5em",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "transparent",
  },

  [theme.breakpoints.down("lg")]: {
    marginTop: "0",
  },
}));

const Navigation = (props: Props) => {
  // ** Props
  const {
    hidden,
    navHover,
    settings,
    afterVerticalNavMenuContent,
    beforeVerticalNavMenuContent,
    verticalNavMenuContent: userVerticalNavMenuContent,
  } = props;

  // ** States
  const [groupActive, setGroupActive] = useState<string[]>([]);
  const [currentActiveGroup, setCurrentActiveGroup] = useState<string[]>([]);

  // ** Ref
  const shadowRef = useRef(null);

  // ** Hooks
  const theme = useTheme();

  // ** Var
  const { skin, navCollapsed } = settings;

  // ** Fixes Navigation InfiniteScroll

  return (
    <Drawer {...props}>
      <NavBox>
        <VerticalNavHeader title={"Cloud Console"} {...props} />
        <Box style={{ flexGrow: 1, flexShrink: 1 }}>
          <List
            className="nav-items"
            sx={{
              transition: "padding .25s ease",
              pr: !navCollapsed || (navCollapsed && navHover) ? 0 : 1.25,
            }}
          >
            <VerticalNavItems
              groupActive={groupActive}
              setGroupActive={setGroupActive}
              currentActiveGroup={currentActiveGroup}
              setCurrentActiveGroup={setCurrentActiveGroup}
              {...props}
            />
          </List>
        </Box>
        <Box
          style={{
            flexGrow: 0,
            flexShrink: 0,
            bottom: 0,
          }}
        >
          <Divider></Divider>
          <VerticalNavHeader title={"My Account"} {...props} />
          <List
            className="nav-items"
            sx={{
              transition: "padding .25s ease",
              pr: !navCollapsed || (navCollapsed && navHover) ? 0 : 1.25,
            }}
          >
            <BottomVerticalNavItems
              groupActive={groupActive}
              setGroupActive={setGroupActive}
              currentActiveGroup={currentActiveGroup}
              setCurrentActiveGroup={setCurrentActiveGroup}
              {...props}
            />
          </List>
        </Box>
      </NavBox>
    </Drawer>
  );
};

export default Navigation;
