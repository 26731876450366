// ** MUI Imports
import { Theme } from "@mui/material/styles";

// ** Theme Type Import
import { Skin } from "src/@core/layouts/types";

const Snackbar = (theme: Theme, skin: Skin) => {
  return {
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          ...(skin === "bordered" && { boxShadow: "none" }),
          backgroundColor:
            theme.palette.mode === "light"
              ? theme.palette.grey[900]
              : theme.palette.grey[100],
        },
      },
    },
  };
};

export default Snackbar;
