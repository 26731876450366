import React, { ReactNode } from "react";

import UserLayout from "src/figmaLayout/UserLayout";

interface LayoutProps {
  children: ReactNode;
}

const FigmaLayout = (props: LayoutProps) => {
  const { children } = props;

  return <UserLayout>{children}</UserLayout>;
};

export default FigmaLayout;
