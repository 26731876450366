import { SVGProps } from "react";

const InsightsLogo = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>,
) => {
  return (
    <svg
      width={props.width ?? "16"}
      height={props.height ?? "16"}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="invisible_box" data-name="invisible box">
          <rect width="48" height="48" fill="none" />
        </g>
        <g id="Health_Icons" data-name="Health Icons">
          <g>
            <path d="M43,4H5A2.9,2.9,0,0,0,2,7V35a2.9,2.9,0,0,0,3,3H43a2.9,2.9,0,0,0,3-3V7A2.9,2.9,0,0,0,43,4ZM42,34H6V22.9H8.6a2,2,0,0,0,1.8-1.2l.5-.9,2.9,6.1A1.9,1.9,0,0,0,15.6,28a2.1,2.1,0,0,0,1.8-1.1l4.8-10.2,2.4,5a2.2,2.2,0,0,0,1.8,1.2H30a2,2,0,0,0,0-4H27.7L24,11.1a2,2,0,0,0-3.6,0L15.6,21.3l-2.9-6.1a2.1,2.1,0,0,0-1.8-1.1,1.9,1.9,0,0,0-1.8,1.1L7.3,18.9H6V8H42Z" />
            <path d="M28,28H24a2,2,0,0,0,0,4h4a2,2,0,0,0,0-4Z" />
            <path d="M34,32h4a2,2,0,0,0,0-4H34a2,2,0,0,0,0,4Z" />
            <path d="M37,40H11a2,2,0,0,0,0,4H37a2,2,0,0,0,0-4Z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default InsightsLogo;
