// ** React Imports
import React, { ReactNode } from "react";

// ** Next Import
import Link from "next/link";

// ** MUI Components
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box, { BoxProps } from "@mui/material/Box";
import BlankLayout from "src/@core/layouts/BlankLayout";
import { getRouteWithParams } from "src/routes";

// ** Styled Components
const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "90vw",
  },
}));

const Img = styled("img")(({ theme }) => ({
  marginBottom: theme.spacing(10),
  [theme.breakpoints.down("lg")]: {
    height: 450,
    marginTop: theme.spacing(10),
  },
  [theme.breakpoints.down("md")]: {
    height: 400,
  },
  [theme.breakpoints.up("lg")]: {
    marginTop: theme.spacing(13),
  },
}));

const Error401 = () => {
  return (
    <Box className="content-center">
      <Box
        sx={{
          p: 5,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
        gap={2}
      >
        <BoxWrapper>
          <Typography variant="h1">401</Typography>
          <Typography
            variant="h5"
            sx={{ mb: 1, fontSize: "1.5rem !important" }}
          >
            You are not authorized 🔐
          </Typography>
          <Typography variant="body2">
            You don&prime;t have the permission to access this page.
          </Typography>
        </BoxWrapper>
        {/* <Img
          height="487"
          alt="error-illustration"
          src="/images/pages/401.png"
        /> */}
        <Link passHref href={getRouteWithParams("dashboard")}>
          <Button component="a" variant="contained" sx={{ px: 5.5 }}>
            Back to Home
          </Button>
        </Link>
      </Box>
      {/* <FooterIllustrations /> */}
    </Box>
  );
};

Error401.getLayout = (page: ReactNode) => <BlankLayout>{page}</BlankLayout>;
Error401.projectGuard = false;

export default Error401;
