const Color = {
  black: "#000",
  white: "#FFF",
  white2: "#EEEEEE",
  white3: "#FBFBFB",
  white4: "#FAFAFA",
  darkerWhite: "#F9F9F9",
  borderGrey: "#DADADA",
  borderLightGrey: "#EEEEEE",
  pillBorderGrey: "#D3D3D3",
  footerGrey: "#7C8088",
  accentPink: "#EC0172",
  OpenGreen: "#34A853",
  OpenPillGreen: "#E3FCE7",
  OpenBlue: "#0052D5",
  OpenPillBlue: "#E4F5FF",
  OpenRed: "#EB5635",
  OpenPillRed: "#FFE3DD",
  closedGrey: "#4B4B4B",
  pillTextBlack: "#2A2A2A",
  timeBlack: "#17191C",
  placeholderBlack: "#757575",
  textLightBlack: "#2B2B2B",
  lightGrey: "#C9C9C9",
  lightGrey2: "#ECECEC",
  threadTimeGrey: "#3E3E3E",
  shimmerWhite1: "#f0f0f0",
  shimmerWhite2: "#e0e0e0",
  attachmentAnchor: "#1e1e1e",
  linearGradient1: "#ed0c79",
  linearGradient2: "#0f0346",
};

export type ColorName = keyof typeof Color;

export default Color;
