import { useContext, useEffect, useCallback } from "react";
import { AxiosError } from "axios";

import axios from "src/services/axios";

import apiConfig from "src/configs/api";
import { S3Context } from "src/context/S3Context";
import { ReactToastifyErrorHandler, errorHandler } from "src/configs/helper";
import { ErrorType } from "src/types/error";
import { toast } from "react-toastify";
import { useRouter } from "next/router";
import useQueryParams from "src/hooks/useQueryParams";
import { useRegion } from "src/hooks/useRegion";
import { errorLogInSentry } from "src/store/sentry";
import { regionProviderMapping } from "src/utils/S3/S3Constants";
import appConfig from "src/configs/app";
import { s3Routes } from "src/configs/constants";

export const useS3Account = () => {
  const router = useRouter();
  const {
    isLoading,
    setIsLoading,
    isS3AccountFound,
    setIsS3AccountFound,
    S3AccountKeys,
    setS3AccountKeys,
    s3AccountId,
    setS3AccountId,
  } = useContext(S3Context);

  const queryParams = useQueryParams();
  const { selectedRegion } = useRegion();
  const region = selectedRegion?.identifier ? selectedRegion.identifier : "";
  const projectId = queryParams[apiConfig.projectId] || "";

  const fetchUserKeys = useCallback(async (): Promise<{
    access_key: string;
    secret_key: string;
    account_id: string;
  }> => {
    const params: { [key: string]: string } = {
      project_id: projectId as string,
      region: region,
    };
    setIsLoading(true);
    try {
      const userKeys = await axios.get(apiConfig.s3Keys, { params });
      if (!userKeys?.data?.data) {
        throw new Error("Failed to fetch account keys");
      }
      return userKeys.data.data;
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, [projectId, region, setIsLoading]);

  const getS3AccountKeys = useCallback(async (): Promise<void> => {
    if (S3AccountKeys.accessKey !== "" || S3AccountKeys.secretKey !== "") {
      return;
    }
    setIsLoading(true);
    try {
      const userKeys = await fetchUserKeys();
      if (
        !userKeys.access_key ||
        !userKeys.secret_key ||
        !userKeys.account_id
      ) {
        throw new Error("Failed to fetch account keys");
      }
      setS3AccountKeys({
        accessKey: userKeys.access_key,
        secretKey: userKeys.secret_key,
      });
      setS3AccountId(userKeys.account_id);
      setIsS3AccountFound(true);
    } catch (err) {
      errorLogInSentry(
        err,
        {},
        "GET",
        "getS3AccountKeys",
        "src/hooks/useS3Account.ts",
      );
      if (err instanceof AxiosError) {
        if (
          err.response?.data?.error &&
          err.response?.data?.message === "S3 Account not found"
        ) {
          setIsS3AccountFound(false);
        } else {
          const errorMessage = ReactToastifyErrorHandler(err);
          toast.error(errorMessage);
        }
      } else {
        const error = err as ErrorType;
        errorHandler(error);
      }
    } finally {
      setIsLoading(false);
    }
  }, [
    S3AccountKeys,
    fetchUserKeys,
    setIsLoading,
    setIsS3AccountFound,
    setS3AccountId,
    setS3AccountKeys,
  ]);

  const generateS3Account = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const generateAccount = await axios.post(
        apiConfig.s3Account + `?project_id=${projectId}&region=${region}`,
      );
      if (
        !generateAccount?.data?.message ||
        generateAccount.data.message.toLowerCase() !== "success"
      ) {
        throw new Error("Failed to generate account keys");
      }
      await getS3AccountKeys();
    } catch (err) {
      const error = err as ErrorType;
      errorLogInSentry(
        err,
        {},
        "POST",
        "generateS3Account",
        "src/hooks/useS3Account.ts",
      );
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      !Object.keys(regionProviderMapping).includes(region) ||
      !appConfig.showS3Storage ||
      !s3Routes.includes(router.pathname)
    ) {
      setIsLoading(false);
      return;
    }
    getS3AccountKeys();
  }, [getS3AccountKeys, region, router, setIsLoading]);

  const getAccessKey = (): string => {
    return S3AccountKeys?.accessKey ? S3AccountKeys.accessKey : "";
  };

  const getSecretKey = (): string => {
    return S3AccountKeys?.secretKey ? S3AccountKeys.secretKey : "";
  };
  const getCannonicalId = (): string => {
    return s3AccountId ?? "";
  };

  const resetS3AccountKeys = (): void => {
    setS3AccountKeys({
      accessKey: "",
      secretKey: "",
    });
    setS3AccountId("");
  };

  return {
    isLoading,
    isS3AccountFound,
    S3AccountKeys,
    generateS3Account,
    getAccessKey,
    getSecretKey,
    getCannonicalId,
    resetS3AccountKeys,
  };
};
