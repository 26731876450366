// ** MUI Imports
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { submitTicketUrl } from "src/configs/constants";

const FooterContent = () => {
  // ** Var
  const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Typography
        sx={{ mr: 2 }}
        variant={"body2"}
      >{`Copyright © ${new Date().getFullYear()} Real Time Cloud Services LLC. All Rights Reserved. `}</Typography>
      {hidden ? null : (
        <Box
          sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
          gap={2}
        >
          <Link
            href={submitTicketUrl}
            sx={{
              textDecoration: "none",
              color: "inherit",
              "&:hover": {
                textDecoration: "underline",
                color: "primary.main", // Change this color as needed
              },
            }}
          >
            <Typography variant={"body2"}>Submit a ticket</Typography>
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default FooterContent;
