// ** React Imports
import React, { ReactNode, useState } from "react";

// ** Next Imports
import { useRouter } from "next/router";

// ** Types
import type { ACLObj, AppAbility } from "src/configs/acl";

// ** Context Imports
import { AbilityContext } from "src/layouts/components/acl/Can";
import { AbilityContext as FigmaLayoutAbilityContext } from "src/figmaLayout/components/acl/Can";

// ** Config Import
import { buildAbilityFor } from "src/configs/acl";

// ** Component Import
import NotAuthorized from "src/pages/401";
import BlankLayout from "src/@core/layouts/BlankLayout";
import { useAuth } from "src/hooks/useAuth";
import appConfig from "src/configs/app";
import { useRegion } from "src/hooks/useRegion";

interface AclGuardProps {
  children: ReactNode;
  guestGuard: boolean;
  aclAbilities: ACLObj;
}

const AclGuard = (props: AclGuardProps) => {
  // ** Hooks

  const router = useRouter();
  const [ability, setAbility] = useState<AppAbility | undefined>(undefined);
  const auth = useAuth();
  const { selectedRegion } = useRegion();
  // ** Props
  const { aclAbilities, children, guestGuard } = props;

  // If guestGuard is true and user is not logged in, or it's an error page, render the page without checking access
  if (
    guestGuard ||
    router.route === "/404" ||
    router.route === "/500" ||
    router.route === "/"
  ) {
    return <>{children}</>;
  }

  // User is logged in, build ability for the user based on his type
  if (auth.user && !ability) {
    setAbility(buildAbilityFor(auth.user, selectedRegion));
  }

  // Check the access of current user and render pages
  if (ability && ability.can(aclAbilities.action, aclAbilities.subject)) {
    return (
      <>
        {appConfig.figmaLayoutSwitcher.toLowerCase() === "on" ? (
          <FigmaLayoutAbilityContext.Provider value={ability}>
            {children}
          </FigmaLayoutAbilityContext.Provider>
        ) : (
          <AbilityContext.Provider value={ability}>
            {children}
          </AbilityContext.Provider>
        )}
      </>
    );
  }

  // // todo: to be confirmed
  // if (auth.user?.type) {
  //   router.push(getHomeRoute(auth.user.type)).then(() => {})
  //   return null
  // }

  // Render Not Authorized component if the current user has limited access
  return (
    <BlankLayout>
      <NotAuthorized />
    </BlankLayout>
  );
};

export default AclGuard;
