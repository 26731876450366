import React, { useContext } from "react";
import { RegionContext } from "src/context/RegionContext";
import { Region } from "src/types/regions";

interface RegionContextType {
  selectedRegion: Region | null;
  setSelectedRegion: (region: Region) => void;
  currentRegion: string;
}

export const useRegion = (): RegionContextType => {
  const context = useContext(RegionContext);
  if (context === undefined) {
    throw new Error("useRegion must be used within a RegionProvider");
  }
  return context;
};
