import { SVGProps } from "react";

const BillingIcon = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>,
) => {
  return (
    <svg
      width={props.width ? props.width : "15"}
      height={props.height ? props.height : "16"}
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.1 16C1.51667 16 1.02083 15.7958 0.6125 15.3875C0.204167 14.9792 0 14.4833 0 13.9V11.4H2.54V0L3.736 1.2L4.932 0L6.128 1.2L7.324 0L8.52 1.2L9.72 0L10.92 1.2L12.12 0L13.32 1.2L14.52 0V13.9C14.52 14.4833 14.3158 14.9792 13.9075 15.3875C13.4992 15.7958 13.0033 16 12.42 16H2.1ZM12.42 14.8C12.6867 14.8 12.9033 14.7167 13.07 14.55C13.2367 14.3833 13.32 14.1667 13.32 13.9V2H3.74V11.4H11.52V13.9C11.52 14.1667 11.6033 14.3833 11.77 14.55C11.9367 14.7167 12.1533 14.8 12.42 14.8ZM4.8 5.16V3.96H9.6V5.16H4.8ZM4.8 7.84V6.64H9.6V7.84H4.8ZM11.46 5.16C11.3 5.16 11.16 5.1 11.04 4.98C10.92 4.86 10.86 4.72 10.86 4.56C10.86 4.4 10.92 4.26 11.04 4.14C11.16 4.02 11.3 3.96 11.46 3.96C11.62 3.96 11.76 4.02 11.88 4.14C12 4.26 12.06 4.4 12.06 4.56C12.06 4.72 12 4.86 11.88 4.98C11.76 5.1 11.62 5.16 11.46 5.16ZM11.46 7.74C11.3 7.74 11.16 7.68 11.04 7.56C10.92 7.44 10.86 7.3 10.86 7.14C10.86 6.98 10.92 6.84 11.04 6.72C11.16 6.6 11.3 6.54 11.46 6.54C11.62 6.54 11.76 6.6 11.88 6.72C12 6.84 12.06 6.98 12.06 7.14C12.06 7.3 12 7.44 11.88 7.56C11.76 7.68 11.62 7.74 11.46 7.74ZM2.08 14.8H10.32V12.6H1.2V13.9C1.2 14.1667 1.28433 14.3833 1.453 14.55C1.62167 14.7167 1.83067 14.8 2.08 14.8Z"
        fill="#4B4B4B"
      />
    </svg>
  );
};

export default BillingIcon;
