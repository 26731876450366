// ** React Imports
import React, { useState, SyntheticEvent, Fragment } from "react";

// ** Next Import
import { useRouter } from "next/router";

// ** MUI Imports
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

// ** Icons Imports

import LogoutVariant from "mdi-material-ui/LogoutVariant";

// ** Type Imports
import { Settings } from "src/@core/context/settingsContext";
import { toast } from "react-hot-toast";

import ArrowDown from "src/assets/icons/arrowDown";
import Profile from "src/assets/icons/profile";
import { useAuth } from "src/hooks/useAuth";
import AccountDetails from "src/assets/icons/accountDetails";
import UserManagement from "src/assets/icons/userManagement";
import Contacts from "src/assets/icons/contacts";
import AccountSecurity from "src/assets/icons/accountSecurity";
import PaymentMethod from "src/assets/icons/paymentMethod";
import EmailHistory from "src/assets/icons/emailHistory";
import Password from "src/assets/icons/password";
import SwitchAccount from "src/assets/icons/switchAccount";
import SecuritySetting from "src/assets/icons/securitySetting";
import Logout from "src/assets/icons/logout";
import appConfig from "src/configs/app";
import { getFullName } from "src/configs/helper";
import { Button, useTheme } from "@mui/material";

interface Props {
  settings: Settings;
}

const UserDropdown = (props: Props) => {
  // ** Props
  const { settings } = props;

  // ** States
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  // ** Hooks
  const router = useRouter();
  const theme = useTheme();

  const auth = useAuth();

  // ** Vars
  const { direction } = settings;

  const handleDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = (url?: string) => {
    if (url) {
      router.push(`${appConfig.oldPortalUrl}${url}`);
    }
    setAnchorEl(null);
  };

  const styles = {
    py: 2,
    px: 4,
    width: "100%",
    display: "flex",
    alignItems: "center",
    color: "text.primary",
    textDecoration: "none",
    "& svg": {
      fontSize: "1.375rem",
      color: "text.secondary",
    },
  };
  const buttonStyle = {
    borderRadius: "20px", // Adjust this value to match the elliptical shape in the image
    boxShadow: "none", // This will remove the button shadow
    backgroundColor: "rgb(242,242,242)",
    color: "rgb(117,117,117)",
    textDecoration: "none",
  };

  return (
    <Fragment>
      <Button
        onClick={handleDropdownOpen}
        variant="contained"
        size={"small"}
        style={buttonStyle}
      >
        <Typography sx={{ textDecoration: "none" }}>
          {getFullName(auth.user)}
        </Typography>
        <ArrowDown style={{ paddingLeft: "10px" }} />

        <Profile fill={theme.palette.divider} />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleDropdownClose()}
        sx={{ "& .MuiMenu-paper": { width: 230, marginTop: 4 } }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: direction === "ltr" ? "right" : "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: direction === "ltr" ? "right" : "left",
        }}
      >
        <Box sx={{ pt: 2, pb: 3, px: 4 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Profile fill={theme.palette.divider} />
            <Box
              sx={{
                display: "flex",
                marginLeft: 3,
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Typography sx={{ fontWeight: 600 }}>
                {getFullName(auth.user)}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider sx={{ mt: 0, mb: 1 }} />
        <MenuItem
          sx={{ p: 0 }}
          onClick={() => handleDropdownClose("/clientarea.php?action=details")}
        >
          <Box sx={styles}>
            <AccountDetails />
            <Typography marginLeft={4}>Account Details</Typography>
          </Box>
        </MenuItem>
        <MenuItem
          sx={{ p: 0 }}
          onClick={() => handleDropdownClose("/index.php?rp=/account/users")}
        >
          <Box sx={styles}>
            <UserManagement />
            <Typography marginLeft={4}>User Management</Typography>
          </Box>
        </MenuItem>
        <MenuItem
          sx={{ p: 0 }}
          onClick={() =>
            handleDropdownClose("/index.php?rp=/account/paymentmethods")
          }
        >
          <Box sx={styles}>
            <PaymentMethod />
            <Typography marginLeft={4}>Payment Methods</Typography>
          </Box>
        </MenuItem>
        <MenuItem
          sx={{ p: 0 }}
          onClick={() => handleDropdownClose("/index.php?rp=/account/contacts")}
        >
          <Box sx={styles}>
            <Contacts />
            <Typography marginLeft={4}>Contacts</Typography>
          </Box>
        </MenuItem>
        <MenuItem
          sx={{ p: 0 }}
          onClick={() => handleDropdownClose("/clientarea.php?action=security")}
        >
          <Box sx={styles}>
            <AccountSecurity />
            <Typography marginLeft={4}>Account Security</Typography>
          </Box>
        </MenuItem>
        <MenuItem
          sx={{ p: 0 }}
          onClick={() => handleDropdownClose("/clientarea.php?action=emails")}
        >
          <Box sx={styles}>
            <EmailHistory />
            <Typography marginLeft={4}>Email History</Typography>
          </Box>
        </MenuItem>
        <Divider />
        <MenuItem
          sx={{ p: 0 }}
          onClick={() => handleDropdownClose("/index.php?rp=/user/profile")}
        >
          <Box sx={styles}>
            <AccountDetails />
            <Typography marginLeft={4}>Your Profile</Typography>
          </Box>
        </MenuItem>
        <MenuItem
          sx={{ p: 0 }}
          onClick={() => handleDropdownClose("/index.php?rp=/user/accounts")}
        >
          <Box sx={styles}>
            <SwitchAccount />
            <Typography marginLeft={4}>Switch Account</Typography>
          </Box>
        </MenuItem>
        <MenuItem
          sx={{ p: 0 }}
          onClick={() => handleDropdownClose("/index.php?rp=/user/password")}
        >
          <Box sx={styles}>
            <Password />
            <Typography marginLeft={4}>Change Password</Typography>
          </Box>
        </MenuItem>
        <MenuItem
          sx={{ p: 0 }}
          onClick={() => handleDropdownClose("/index.php?rp=/user/security")}
        >
          <Box sx={styles}>
            <SecuritySetting />
            <Typography marginLeft={4}>Security Settings</Typography>
          </Box>
        </MenuItem>
        <Divider />
        <MenuItem
          sx={{ p: 0 }}
          onClick={() =>
            auth.logout((err: object) => {
              toast.error(
                "Unable to process the request, please try again after sometime",
                {
                  duration: 5000,
                },
              );
              router.push("/");
            })
          }
        >
          <Box sx={styles}>
            <Logout />
            <Typography marginLeft={4}>Logout</Typography>
          </Box>
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

export default UserDropdown;
