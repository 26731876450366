// ** React Imports
import React, { ReactNode, ReactElement, useEffect } from "react";

// ** Next Imports
import { useRouter } from "next/router";
import { useAuth } from "src/hooks/useAuth";
import apiConfig from "src/configs/api";
import { getRoute } from "src/routes";
import Cookies from "js-cookie";
import { pushToLogin } from "src/configs/helper";
import useProjects from "src/hooks/useProjects";
import useKYCStatus from "src/hooks/useKYCStatus";

interface AuthGuardProps {
  children: ReactNode;
  fallback: ReactElement | null;
}

const AuthGuard = (props: AuthGuardProps) => {
  const { children, fallback } = props;

  const auth = useAuth();
  const { isProjectsFetching } = useProjects();
  const { isKYCFetching } = useKYCStatus();

  const router = useRouter();

  window.addEventListener("pageshow", (event) => {
    if (
      event.persisted &&
      !localStorage.getItem(apiConfig.storageTokenKeyName) &&
      !Cookies.get(apiConfig.userRemember)
    ) {
      // Page loaded from bfcache and user is not logged in
      pushToLogin();
    }
  });

  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    if (
      auth.user === null &&
      !localStorage.getItem(apiConfig.storageTokenKeyName) &&
      !Cookies.get(apiConfig.userRemember)
    ) {
      if (router.asPath !== "/") {
        pushToLogin();
      } else {
        pushToLogin();
      }
    }
  }, [router.route]);

  if (
    auth.loading ||
    auth.user === null ||
    isProjectsFetching ||
    isKYCFetching
  ) {
    return fallback;
  }

  return <>{children}</>;
};

export default AuthGuard;
