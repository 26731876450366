// ** React Imports
import React, { ReactNode, ReactElement, useEffect } from "react";

// ** Next Imports
import { useRouter } from "next/router";

// ** Hooks Import

import apiConfig from "src/configs/api";
import { useAuth } from "src/hooks/useAuth";
import { getRoute, getRouteWithParams } from "src/routes";

interface GuestGuardProps {
  children: ReactNode;
  fallback: ReactElement | null;
}

const GuestGuard = (props: GuestGuardProps) => {
  const { children, fallback } = props;
  const auth = useAuth();
  const router = useRouter();

  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    if (localStorage.getItem(apiConfig.storageTokenKeyName)) {
      router.replace(getRouteWithParams("dashboard"));
    }
  }, [router.route]);

  if (auth.loading || (!auth.loading && auth.user !== null)) {
    return fallback;
  }

  return <>{children}</>;
};

export default GuestGuard;
