import Ticket from "mdi-material-ui/Ticket";
import { VerticalNavItemsType } from "src/@core/layouts/types";
import Billing from "src/assets/icons/billing";
import Dashboard from "src/assets/icons/dashboard";
import Networks from "src/assets/icons/networks";
import Volume from "src/assets/icons/volume";
import { publicCloudUrl } from "src/configs/constants";
import { getRoute } from "src/routes";
import { getPublicCloudUrl } from "src/utils/publicCloud";

const bottomNavigationList = (): VerticalNavItemsType => {
  return [
    {
      title: "Order New Service",
      icon: Volume,
      children: [
        {
          title: "SSL Certificate",
          action: "view",
          subject: "acl",
          path: getPublicCloudUrl(),
        },
        {
          title: "Public Cloud Projects",
          action: "view",
          subject: "acl",
          path: getPublicCloudUrl(),
        },
      ],
    },
    {
      title: "My Services",
      icon: Volume,
      action: "view",
      subject: "acl",
      path: getPublicCloudUrl(),
    },
    {
      title: "Account Billing",
      icon: Dashboard,
      children: [
        {
          title: "Add Funds",
          action: "view",
          subject: "acl",
          path: publicCloudUrl + "?action=addfunds",
        },
        {
          title: "My Quotes",
          action: "view",
          subject: "acl",
          path: publicCloudUrl + "?action=quotes",
        },
        {
          title: "My Invoices",
          action: "view",
          subject: "acl",
          path: publicCloudUrl + "?action=invoices",
        },
        // {
        //   title: "Credit History",
        //   action: "view",
        //   subject: "acl",
        //   path: publicCloudUrl + "?action=creditmanager",
        // },
        {
          title: "Payment Methods",
          action: "view",
          subject: "acl",
          path: getPublicCloudUrl(),
        },
      ],
    },
    {
      title: "Support",
      icon: Ticket,
      children: [
        {
          title: "Tickets",
          action: "view",
          subject: "acl",
          path: getPublicCloudUrl(),
        },
        {
          title: "Downloads",
          action: "view",
          subject: "acl",
          path: getPublicCloudUrl(),
        },
        {
          title: "Create Ticket",
          action: "view",
          subject: "acl",
          path: getPublicCloudUrl(),
        },
        {
          title: "Network Status",
          action: "view",
          subject: "acl",
          path: getPublicCloudUrl(),
        },
        {
          title: "Announcements",
          action: "view",
          subject: "acl",
          path: getPublicCloudUrl(),
        },
        {
          title: "Knowledge Base",
          action: "view",
          subject: "acl",
          path: getPublicCloudUrl(),
        },
      ],
    },
  ];
};

export default bottomNavigationList;
