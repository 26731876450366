import { useState } from "react";

const useModal = () => {
  const [modalActive, setModalActive] = useState<{
    modalName: string;
    isModalOpen: boolean;
  }>({ modalName: "", isModalOpen: false });

  const handleOpenModal = (modalName: string) => {
    setModalActive({ modalName, isModalOpen: true });
  };

  const handleCloseModal = () => {
    setModalActive({ modalName: "", isModalOpen: false });
  };

  return { modalActive, handleOpenModal, handleCloseModal };
};

export default useModal;
