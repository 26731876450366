import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useRouter } from "next/router";

import { getRouteWithParams } from "src/routes";

type KYCCompletionModalProps = {
  open: boolean;
  onCloseModal: () => void;
};

export default function KycDialog({
  open,
  onCloseModal,
}: KYCCompletionModalProps) {
  const router = useRouter();

  const handleKYCRedirect = async () => {
    await router.push(getRouteWithParams("kycPage"));
    onCloseModal();
  };

  return (
    <Dialog open={open} onClose={onCloseModal} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ pb: 2 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body1" fontSize="14px" fontWeight={500}>
            KYC Required
          </Typography>
          <Button onClick={onCloseModal} size="small">
            <CloseIcon fontSize="small" />
          </Button>
        </Box>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography variant="body1" fontSize="13px">
            To proceed with this action, we kindly request you to complete the
            Know Your Customer (KYC) verification process.
          </Typography>
          <Box display="flex" justifyContent="end">
            <Button
              variant="contained"
              color="primary"
              onClick={handleKYCRedirect}
              size="small"
            >
              Complete KYC
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
