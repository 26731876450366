import { useRouter } from "next/router";
import React, {
  useState,
  createContext,
  ReactNode,
  FC,
  useCallback,
  useEffect,
  useMemo,
} from "react";

import { useAuth } from "src/hooks/useAuth";
import { PolicyType, ProjectType } from "src/context/types";
import showToast from "src/utils/notifications";
import { fetchProjects } from "src/store/projects";
import apiConfig from "src/configs/api";
import { getRoute } from "src/routes";
import { isEmptyObject } from "src/configs/helper";
import { toastMessage } from "src/language/en/message";

interface ProjectContextType {
  projects: ProjectType | null;
  selectedProjectID: string;
  isProjectsFetching: boolean;
  setSelectedProjectID: React.Dispatch<React.SetStateAction<string>>;
  policies: PolicyType[] | null;
}

const defaultValues = {
  projects: null,
  isProjectsFetching: false,
  selectedProjectID: "",
  setSelectedProjectID: () => { },
  policies : null
};

export const ProjectContext = createContext<ProjectContextType>(defaultValues);

const ProjectProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { user } = useAuth();
  const router = useRouter();
  const [projects, setProjects] = useState<ProjectType | null>(null);
  const [isProjectsLoading, setIsProjectsLoading] = useState<boolean>(false);
  const [selectedProjectID, setSelectedProjectID] = useState<string>("");
  const [policies,setPolicies] = useState<PolicyType[] | null>(null)

  const queryParams = router.query;
  const projectID = queryParams[apiConfig.projectId] as string;

  const getProjects = useCallback(async () => {
    if (!router.isReady || !user) {
      return;
    }
    if(router.pathname === getRoute("zohoTickets") || router.pathname ===  getRoute("zohoDashboard") || router.pathname === getRoute('createTicket') || router.pathname === getRoute('orderProject') ||
    router.pathname.includes("iam") || router.pathname.includes("billing")) {
      return;
    }

    setIsProjectsLoading(true);

    try {
      const projects = await fetchProjects()
      const projectsResponse : ProjectType = projects.data ;
      const policies:PolicyType[] = projects.policies
      setPolicies(policies)

      if (
        !projectsResponse ||
        isEmptyObject(projectsResponse) ||
        !Object.keys(projectsResponse).length
      ) {
        setProjects(null);
        setIsProjectsLoading(false);
        return;
      }
      setProjects(projectsResponse);
      if (projectID && projectsResponse[projectID]) {
        setSelectedProjectID(projectID);
        const currentRegion = router.query[apiConfig.currentRegion]
        if(typeof currentRegion === 'string' && !projectsResponse[projectID]?.regions[currentRegion]?.is_accessible && router.pathname !== getRoute('getStarted')) {
          router.push({
            pathname: getRoute("getStarted"),
          query: {
            ...router.query,
          }})
        }
      } else {
        if (
          projectID &&
          !projectsResponse[projectID] &&
          router.pathname === "/dashboard"
        ) {
          showToast(
            "error",
            toastMessage.frontendError.defaultProjectIdErrorMessage,
          );
        }
        const defaultProjectId = Object.keys(projectsResponse)[0];
        const hostingId = projectsResponse[defaultProjectId].hostingId;

        setSelectedProjectID(defaultProjectId);

        const currentRegion = router.query[apiConfig.currentRegion]
        const availableRegion = (typeof currentRegion === 'string' && projectsResponse[defaultProjectId]?.regions[currentRegion]) ? currentRegion : typeof projectsResponse?.[defaultProjectId] === "object" && Object.keys(projectsResponse[defaultProjectId]?.regions)[0]
        if(projectsResponse[projectID] && typeof currentRegion === 'string' && !projectsResponse[projectID]?.regions[currentRegion]?.is_accessible && router.pathname !== getRoute('getStarted')) {
          router.push({
            pathname: getRoute("getStarted"),
          query: {
            ...router.query,
          }})
        } else if(router.pathname !== getRoute("dashboard") || router.query?.[apiConfig.projectId] !== defaultProjectId || router.query?.[apiConfig.hostingId] !== hostingId || availableRegion !==currentRegion   ) {
            router.push({
              pathname: getRoute("dashboard"),
              query: {
                ...router.query,
                [apiConfig.currentRegion] : availableRegion,
                [apiConfig.projectId]: defaultProjectId,
                [apiConfig.hostingId]: hostingId,
              },
            });
          }
            
      }
    } catch (error) {
      showToast(
        "error",
        toastMessage.frontendError.projectFetchingErrorMessage,
      );
    } finally {
      setIsProjectsLoading(false);
    }
  }, [user, projectID, router]);

  useEffect(() => {
    if (user) {
      getProjects();

    } else {
      setProjects(null);
      setSelectedProjectID("");
    }
  }, [user, getProjects]);

  useEffect(() => {

    const handleRouteChange = (url: string) => {
      if (url === "/dashboard" && user) {
        getProjects();
      }
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    if (router.pathname === "/dashboard" && user) {
      getProjects();
    }

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events, router.pathname, user, getProjects]);

  const contextValue = useMemo(
    () => ({
      projects,
      isProjectsFetching: isProjectsLoading,
      selectedProjectID,
      setSelectedProjectID,
      policies
    }),
    [projects, isProjectsLoading, selectedProjectID, setSelectedProjectID,policies],
  );

  return (
    <ProjectContext.Provider value={contextValue}>
      {children}
    </ProjectContext.Provider>
  );
};

export default ProjectProvider;
