import apiConfig from "src/configs/api";
import appConfig from "src/configs/app";
import { publicCloudCartUrl, publicCloudUrl } from "src/configs/constants";

export const getPublicCloudUrl = (pageUrl?: string) => {
  const queryParams = new URLSearchParams(
    window && window.location && window.location.search
      ? window.location.search
      : "",
  );
  const id = queryParams.get(apiConfig.hostingId) || "";
  const region = queryParams.get(apiConfig.currentRegion) || "";
  const project_id = queryParams.get(apiConfig.projectId) || "";
  const timezone = queryParams.get(apiConfig.timezone) || "";
  const url = publicCloudUrl;

  if (pageUrl && id) {
    let params = `?action=productdetails&id=${id}`;
    params += `&modop=custom&a=${encodeURIComponent(pageUrl)}`;
    if (region) params += `&region=${region}`;
    if (project_id) params += `&project_id=${project_id}`;
    if (timezone) params += `&timezone=${timezone}`;

    return `${url}${params}`;
  }

  return url;
};

export const getPublicCloudCartUrl = (pageUrl?: string) => {
  const url = publicCloudCartUrl;
  if (pageUrl && pageUrl.length) {
    const params = `?a=${pageUrl}`;

    return url + params;
  }
  return url;
};

export const publicCloudLogoutUrl = appConfig.oldPortalUrl + "/logout.php";
