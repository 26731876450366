// ** React Imports
import { ReactNode } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

// ** Type Import
import { Settings } from "src/@core/context/settingsContext";

// ** Footer Content Component
import FooterContent from "./FooterContent";

interface Props {
  settings: Settings;
  showBackdrop: boolean;
  saveSettings: (values: Settings) => void;
  footerContent?: (props?: any) => ReactNode;
}

const Footer = (props: Props) => {
  // ** Props
  const { settings, showBackdrop, footerContent: userFooterContent } = props;

  // ** Hook
  const theme = useTheme();

  // ** Vars
  const { skin, footer, contentWidth } = settings;

  if (footer === "hidden") {
    return null;
  }

  return (
    <Box
      component="footer"
      className="layout-footer"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: showBackdrop && footer === "fixed" ? 13 : 10,
        ...(footer === "fixed" && {
          bottom: 0,
          px: [4, 6],
          position: "sticky",
        }),
      }}
    >
      <Box
        className="footer-content-container"
        sx={{
          py: 4,
          width: "100%",
          margin:"0% 1.5rem",
          padding: "1%",
          borderTop: `1px solid ${theme.palette.divider}`,
          ...(contentWidth === "boxed" && {
            "@media (min-width:1440px)": { maxWidth: 1440 },
          }),
          ...(footer === "fixed" && {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[skin === "bordered" ? 0 : 4],
            ...(contentWidth === "boxed" && {
              "@media (min-width:1440px)": {
                maxWidth: `calc(1440px - ${theme.spacing(6)} * 2)`,
              },
            }),
            ...(skin === "bordered"
              ? {
                  border: `1px solid ${theme.palette.divider}`,
                  borderBottomWidth: 0,
                }
              : {
                  boxShadow: `0 -4px 8px -2px rgba(${
                    theme.palette.mode === "light"
                      ? theme.palette.customColors.main
                      : "19, 17, 32"
                  }, ${theme.palette.mode === "light" ? 0.2 : 0.42})`,
                }),
          }),
        }}
      >
        <FooterContent />
      </Box>
    </Box>
  );
};

export default Footer;
