import { SVGProps } from "react";

const PaymentMethod = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>,
) => {
  return (
    <svg
      width={props.width ? props.width : "15"}
      height={props.height ? props.height : "14"}
      viewBox="0 0 15 14"
      fill={props.fill ? props.fill : "none"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.3444 8.24444C10.6685 8.24444 10.9569 8.11806 11.2097 7.86528C11.4625 7.6125 11.5889 7.32083 11.5889 6.99028C11.5889 6.65972 11.4625 6.3713 11.2097 6.125C10.9569 5.8787 10.6685 5.75556 10.3444 5.75556C10.0204 5.75556 9.73195 5.8787 9.47917 6.125C9.22639 6.3713 9.1 6.65972 9.1 6.99028C9.1 7.32083 9.22639 7.6125 9.47917 7.86528C9.73195 8.11806 10.0204 8.24444 10.3444 8.24444ZM1.16667 11.8028V12.8333V1.16667V11.8028ZM1.16667 14C0.868519 14 0.599537 13.8833 0.359722 13.65C0.119907 13.4167 0 13.1444 0 12.8333V1.16667C0 0.868519 0.119907 0.599537 0.359722 0.359722C0.599537 0.119907 0.868519 0 1.16667 0H12.8333C13.1444 0 13.4167 0.119907 13.65 0.359722C13.8833 0.599537 14 0.868519 14 1.16667V3.77222H12.8333V1.16667H1.16667V12.8333H12.8333V10.2472H14V12.8333C14 13.1444 13.8833 13.4167 13.65 13.65C13.4167 13.8833 13.1444 14 12.8333 14H1.16667ZM8.12778 10.6361C7.68704 10.6361 7.33704 10.5065 7.07778 10.2472C6.81852 9.98796 6.68889 9.64444 6.68889 9.21667V4.80278C6.68889 4.36204 6.81852 4.01528 7.07778 3.7625C7.33704 3.50972 7.68704 3.38333 8.12778 3.38333H13.3778C13.8185 3.38333 14.1685 3.50972 14.4278 3.7625C14.687 4.01528 14.8167 4.36204 14.8167 4.80278V9.21667C14.8167 9.64444 14.687 9.98796 14.4278 10.2472C14.1685 10.5065 13.8185 10.6361 13.3778 10.6361H8.12778ZM13.65 9.46945V4.55H7.85556V9.46945H13.65Z"
        fill="#757575"
        className="grey-100-fill"
      ></path>
    </svg>
  );
};

export default PaymentMethod;
