import { JSX, SVGProps } from "react";

const ArrowDown = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>,
) => {
  return (
    <svg
      width={props.width ? props.width : "10"}
      height={props.height ? props.height : "5"}
      viewBox="0 0 10 5"
      fill={props.fill ? props.fill : "none"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.79289 -3.8435e-07C9.23835 -4.03821e-07 9.46143 0.53857 9.14645 0.853553L5.35355 4.64645C5.15829 4.84171 4.84171 4.84171 4.64645 4.64645L0.853553 0.853553C0.53857 0.53857 0.761654 -3.3293e-08 1.20711 -5.27643e-08L8.79289 -3.8435e-07Z"
        fill="#757575"
      />
    </svg>
  );
};

export default ArrowDown;
