const Select = {
  MuiSelect: {
    styleOverrides: {
      select: {
        minWidth: "1.5rem !important",
        "&.MuiTablePagination-select": {
          minWidth: "1.5rem !important",
        },
      },
    },
  },
};

export default Select;
