// ** React Imports
import { ReactNode } from "react";

// ** MUI Imports
import { styled, useTheme } from "@mui/material/styles";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import MuiAppBar, { AppBarProps } from "@mui/material/AppBar";
import MuiToolbar, { ToolbarProps } from "@mui/material/Toolbar";

// ** Type Import
import { Settings } from "src/@core/context/settingsContext";

// ** Util Import
import { hexToRGBA } from "src/@core/utils/hex-to-rgba";
import { Box, Divider, IconButton, Tooltip } from "@mui/material";

import { ArrowRight, Menu } from "mdi-material-ui";

import Image from "next/image";
import themeConfig from "src/configs/themeConfig";
import Link from "next/link";
import Cart from "src/assets/images/cart";
import RegionSelector from "src/layouts/appBar/RegionSelector";
import { getPublicCloudCartUrl } from "src/utils/publicCloud";
import UserDropdown from "src/layouts/appBar/UserProfile";
import appConfig from "src/configs/app";

interface Props {
  hidden: boolean;
  settings: Settings;
  toggleNavVisibility: () => void;
  setShowBackdrop: (val: boolean) => void;
  saveSettings: (values: Settings) => void;
  verticalAppBarContent?: (props?: any) => ReactNode;
}

const AppBar = styled(MuiAppBar)<AppBarProps>(({ theme }) => ({
  transition: "none",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 6),
  backgroundColor: theme.palette.background.paper,
  borderBottom: `1px solid ${theme.palette.divider}`,
  color: theme.palette.text.primary,
  minHeight: theme.mixins.toolbar.minHeight,
  [theme.breakpoints.down("sm")]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));

const Toolbar = styled(MuiToolbar)<ToolbarProps>(({ theme }) => ({
  width: "100%",
  borderBottomLeftRadius: 10,
  borderBottomRightRadius: 10,
  padding: `${theme.spacing(0)} !important`,
  minHeight: `${theme.mixins.toolbar.minHeight}px !important`,
  transition:
    "padding .25s ease-in-out, box-shadow .25s ease-in-out, backdrop-filter .25s ease-in-out, background-color .25s ease-in-out",
}));

const LayoutAppBar = (props: Props) => {
  // ** Props
  const {
    settings,
    verticalAppBarContent: userVerticalAppBarContent,
    saveSettings,
    toggleNavVisibility,
    hidden,
  } = props;

  // ** Hooks
  const theme = useTheme();
  const scrollTrigger = useScrollTrigger({
    threshold: 0,
    disableHysteresis: true,
  });

  // ** Vars
  const { skin, appBar, appBarBlur, navCollapsed, contentWidth } = settings;

  const MenuLockedIcon = () => <Menu fontSize="large" />;

  const MenuUnlockedIcon = () => <ArrowRight fontSize="large" />;

  const appBarFixedStyles = () => {
    return {
      paddingLeft: `${theme.spacing(5)} !important`,
      paddingRight: `${theme.spacing(5)} !important`,
      ...(appBarBlur && { backdropFilter: "blur(8px)" }),
      boxShadow: theme.shadows[skin === "bordered" ? 0 : 3],
      backgroundColor: hexToRGBA(
        theme.palette.background.paper,
        appBarBlur ? 0.85 : 1,
      ),
      ...(skin === "bordered" && {
        border: `1px solid ${theme.palette.divider}`,
        borderTopWidth: 0,
      }),
    };
  };

  if (appBar === "hidden") {
    return null;
  }

  return (
    <AppBar
      elevation={0}
      color="error"
      position={"sticky"}
      sx={{
        paddingX: "12px",
        ...(appBar === "fixed" && scrollTrigger && { ...appBarFixedStyles() }),
        ...(contentWidth === "boxed" && {
          "@media (min-width:1440px)": {
            maxWidth: `calc(1440px - ${theme.spacing(6)} * 2)`,
          },
        }),
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          className="actions-left"
          sx={{ display: "flex", alignItems: "center", gap: 4 }}
        >
          <Link href="/">
            <Image
              src={themeConfig.appLogoPath}
              alt={themeConfig.templateName}
              width="56"
              height={"45"}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = themeConfig.appLogoAlternativePath;
              }}
            />
          </Link>

          {hidden ? (
            <IconButton color="inherit" onClick={toggleNavVisibility}>
              <Menu fontSize="large" />
            </IconButton>
          ) : (
            <IconButton
              disableRipple
              disableFocusRipple
              onClick={() =>
                saveSettings({ ...settings, navCollapsed: !navCollapsed })
              }
              size="small"
              sx={{
                padding: 0,
                color: "text.primary",
                backgroundColor: "transparent !important",
              }}
            >
              {navCollapsed ? MenuUnlockedIcon() : MenuLockedIcon()}
            </IconButton>
          )}
        </Box>
        <Box
          className="actions-right"
          sx={{ display: "flex", alignItems: "center" }}
          gap={6}
        >
          <Link href={getPublicCloudCartUrl("checkout")}>
            <Tooltip title={"View Cart"}>
              <Cart />
            </Tooltip>
          </Link>
          <Divider orientation="vertical" flexItem />
          <RegionSelector />
          <UserDropdown settings={settings} />
        </Box>
      </Box>
    </AppBar>
  );
};

export default LayoutAppBar;
